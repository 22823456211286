<template>
	<div class="bg-struct bg-struct-details bg-img bg-img-19"></div>
	<div class="main-cont">
		<div id="blog-sec" class="mb-5">&nbsp;</div>
		<section class="blog-sec">
			<div class="d-flex justify-content-between mb-3">
				<h4 style="z-index: 1;">Добавление записи блога</h4>
				<div class="btn-group ms-1" role="group" v-if="authModel.isAdmin()">
					<router-link :to="{path: '/blog', query: Object.assign({}, this.$route.query, {
						category: this.$route.query.category,
						page: this.$route.query.page,
					})}" class="btn btn-secondary">
						<font-awesome-icon :icon="['fas', 'chevron-left']" />
						<span class="ms-2">Назад</span>
					</router-link>
				</div>
			</div>
			<div class="card border-0 shadow-sm text-center" v-if="loading">
				<div class="card-body py-4">
					<loading-spinner color="white" text="ЗАГРУЗКА..." />
				</div>
			</div>
			<div class="card border-0 shadow-sm" v-if="!loading">
				<form role="form" @submit.prevent="onAdd()">
					<div class="card-body">
						<div class="mb-3">
							<label for="blogCategoryId" class="form-label">Категория <span class="text-danger">*</span></label>
							<select class="form-select" ref="category_id" id="blogCategoryId" v-model="category_id" required>
								<option :value="blogcat.id" v-for="blogcat in blogCategories" :key="blogcat.id">{{blogcat.name}}</option>
							</select>
						</div>
						<div class="mb-3">
							<label for="blogTitle" class="form-label">Заголовок <span class="text-danger">*</span></label>
							<input type="text" class="form-control" ref="title" id="blogTitle" v-model="title" required autofocus />
						</div>
						<div class="mb-3">
							<label for="blogContent" class="form-label">Контент <span class="text-danger">*</span></label>
							<tinymce-editor
								ref="content"
								id="blogContent"
								v-model="content"
								api-key="no-api-key"
								:init="{
									language_url: '/assets/plugins/tinymce/langs/ru.js',
									language: 'ru',
									plugins: 'accordion advlist anchor autolink autoresize charmap code directionality fullscreen image insertdatetime link lists media nonbreaking pagebreak preview quickbars searchreplace table visualblocks visualchars wordcount',
									toolbar: 'undo redo | styles fontfamily fontsize | bold italic underline strikethrough blockquote checklist bullist numlist backcolor forecolor | link image media hr codeinline | alignleft aligncenter alignright alignjustify | code removeformat',
									mobile: {
										menubar: true,
										//toolbar_mode: 'scrolling',
									},
									setup: (editor) => {
										editor.ui.registry.addButton('codeinline', {
											icon: 'sourcecode',
											tooltip: 'Форматировать как Код',
											onAction: () => editor.execCommand('mceToggleFormat', false, 'code'),
										});
									},
									promotion: false,
								}"
							/>
						</div>
						<div class="mb-3">
							<label for="blogDateadd" class="form-label">Дата и время <span class="text-danger">*</span></label>
							<input type="datetime-local" class="form-control" ref="dateadd" id="blogDateadd" v-model="dateadd" required />
						</div>
					</div>
					<div class="card-footer text-center">
						<button type="submit" class="btn btn-primary">Добавить</button>
					</div>
					<div class="card-body" v-if="error">
						<div class="alert alert-warning alert-dismissible mb-0" role="alert">
							{{error}}
							<button type="button" class="btn-close" aria-label="Close" @click="error = ''"></button>
						</div>
					</div>
				</form>
			</div>
		</section>
	</div>
</template>

<style lang="sass">

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

import LoadingSpinner from '@/components/LoadingSpinner';

// TinyMCE
import 'tinymce/tinymce';
import 'tinymce/icons/default/icons';
import 'tinymce/themes/silver/theme';
import 'tinymce/models/dom/model';
import 'tinymce/skins/ui/oxide-dark/skin.css';

// TinyMCE plugins
// https://www.tiny.cloud/docs/tinymce/6/plugins/
import 'tinymce/plugins/accordion/plugin';
import 'tinymce/plugins/advlist/plugin';
import 'tinymce/plugins/anchor/plugin';
import 'tinymce/plugins/autolink/plugin';
import 'tinymce/plugins/autoresize/plugin';
import 'tinymce/plugins/charmap/plugin';
import 'tinymce/plugins/code/plugin';
import 'tinymce/plugins/directionality/plugin';
import 'tinymce/plugins/fullscreen/plugin';
import 'tinymce/plugins/image/plugin';
import 'tinymce/plugins/insertdatetime/plugin';
import 'tinymce/plugins/link/plugin';
import 'tinymce/plugins/lists/plugin';
import 'tinymce/plugins/media/plugin';
import 'tinymce/plugins/nonbreaking/plugin';
import 'tinymce/plugins/pagebreak/plugin';
import 'tinymce/plugins/preview/plugin';
import 'tinymce/plugins/quickbars/plugin';
import 'tinymce/plugins/searchreplace/plugin';
import 'tinymce/plugins/table/plugin';
import 'tinymce/plugins/visualblocks/plugin';
import 'tinymce/plugins/visualchars/plugin';
import 'tinymce/plugins/wordcount/plugin';

import TinymceEditor from '@tinymce/tinymce-vue';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Добавление записи блога | Админ-панель | byLex Portfolio'});
	},
	data: () => ({
		blogCategories: [],
		
		category_id: '',
		title: '',
		content: '',
		dateadd: '',
		
		loading: true,
		error: '',
	}),
	computed: {},
	methods: {
		formatDate: lib.formatDate,
		formatDateUtc: lib.formatDateUtc,
		
		async loadBlogCategories(){
			this.error = '';
			let ret = await this.appModel.doGetBlogCategories();
			if(ret.success){
				this.blogCategories = ret.list;
			} else {
				this.error = ret.message;
			}
		},
		
		async onAdd(){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doAddBlogItem({
				category_id: this.category_id,
				title: this.title,
				content: this.content,
				dateadd: this.formatDateUtc(this.dateadd, 'YYYY-MM-DDTHH:mm'),
			});
			if(ret.success){
				this.$router.push({path: '/admin/blog/'+ret.id});
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
	},
	async mounted(){
		this.dateadd = this.formatDate(new Date().toUTCString(), 'YYYY-MM-DDTHH:mm');
		
		this.loading = true;
		await this.loadBlogCategories();
		if(this.blogCategories.length > 0){
			this.category_id = this.blogCategories[0].id;
		}
		this.loading = false;
	},
	components: {
		LoadingSpinner,
		TinymceEditor,
	},
}
</script>