<template>
	<div class="bg-struct bg-struct-details bg-img bg-img-18"></div>
	<div class="main-cont">
		<div id="project-sec" class="mb-5">&nbsp;</div>
		<section class="project-sec">
			<div class="card border-0 shadow-sm text-center" v-if="loading">
				<div class="card-body py-4">
					<loading-spinner color="white" text="ЗАГРУЗКА..." />
				</div>
			</div>
			<div class="card border-0 shadow-sm text-center"  v-if="!loading && !work">
				<div class="card-body py-4">
					<h1>ЗАПИСЬ НЕ НАЙДЕНА</h1>
					<div class="mt-4">
						<router-link :to="{path: '/works', query: Object.assign({}, this.$route.query, {
							category: this.$route.query.category,
							page: this.$route.query.page||1,
						})}" class="btn btn-secondary">
							<font-awesome-icon :icon="['fas', 'chevron-left']" />
							<span class="ms-2">Назад</span>	
						</router-link>
					</div>
				</div>
			</div>
			<div class="card border-0 shadow-sm" v-if="!loading && work">
				<div class="card-header text-end">
					<div class="d-grid gap-2 d-md-block">
						<router-link :to="{path: '/works', query: Object.assign({}, this.$route.query, {
							category: this.$route.query.category,
							page: this.$route.query.page||1,
						})}" class="btn btn-secondary">
							<font-awesome-icon :icon="['fas', 'chevron-left']" />
							<span class="ms-2">Назад</span>	
						</router-link>
						<router-link :to="{path: '/admin/works/'+$route.params.id, query: Object.assign({}, this.$route.query, {
							category: this.$route.query.category,
							page: this.$route.query.page||1,
						})}" class="btn btn-primary ms-md-2" v-if="authModel.isAdmin()">
							<font-awesome-icon :icon="['fas', 'pen-to-square']" />
							<span class="ms-2">Изменить</span>
						</router-link>
						<button @click="onDelete()" class="btn btn-danger ms-md-2" v-if="authModel.isAdmin()">
							<font-awesome-icon :icon="['fas', 'trash-can']" />
							<span class="ms-2">Удалить</span>
						</button>
					</div>
				</div>
				<div class="card-body">
					<div class="float-end" v-if="work.site_url">
						<a :href="work.site_url" target="_blank" class="btn btn-success">Посмотреть</a>
					</div>
					<h4 class="mt-1 mb-4">{{work.title}}</h4>
					<div v-html="work.content"></div>
					<ul class="list-unstyled mt-4 mb-0">
						<li class="font-capitalize">
							<font-awesome-icon :icon="['fas', 'tag']" class="me-2" />
							<span>{{work.category_name}}</span>
						</li>
					</ul>
				</div>
				<div class="card-body border-1 border-top" v-if="work.models3d.length > 0">
					<div class="row row-cols-1 g-3">
						<div class="col" v-for="(model3d, index) in work.models3d" :key="model3d.id">
							<div class="card border-1 h-100 position-relative">
								<a :href="model3d.url" class="btn btn-primary position-absolute top-0 end-0 mt-2 me-2" style="z-index: 1;" title="Скачать 3d модель" download>
									<font-awesome-icon :icon="['fas', 'download']" />
								</a>
								<component :is="getModelComponentByExt(model3d.extension)"
									:backgroundAlpha="0"
									:rotation="model3d_rotation"
									:lights="model3d_lights"
									:src="model3d.url"
								/>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body border-1 border-top" v-if="work.images.length > 0">
					<lightgallery
						:settings="lightgallerySettings"
						:onInit="onLightGalleryInit"
						class="row row-cols-1 row-cols-md-3 row-cols-lg-4 g-4"
					>
						<div class="col" v-for="(image, index) in work.images" :key="image.id">
							<div class="card border-1 h-100">
								<a :href="image.url" class="image">
									<div class="workitem card-img-cont">
										<div class="card-img-out">
											<div class="card-img-in">
												<img :src="image.url_small" />
											</div>
										</div>
									</div>
								</a>
							</div>
						</div>
					</lightgallery>
				</div>
				<div class="card-body border-1 border-top" v-if="work.videos.length > 0">
					<lightgallery
						:settings="lightgallerySettings2"
						:onInit="onLightGalleryInit2"
						class="row row-cols-2 row-cols-md-4 row-cols-lg-6 g-4"
					>
						<div class="col" v-for="(video, index) in work.videos" :key="video.id">
							<div class="card border-1 h-100 text-center p-2">
								<a :data-video="editedVideoSource(video)" :data-sub-html="video.descr" class="video block" style="cursor: pointer;">
									<font-awesome-icon :icon="['fas', 'film']" size="6x" />
								</a>
							</div>
						</div>
					</lightgallery>
				</div>
			</div>
		</section>
	</div>
</template>

<style lang="sass" scoped>
@import 'lightgallery/css/lightgallery.css';
@import 'lightgallery/css/lg-rotate.css';
@import 'lightgallery/css/lg-pager.css';
@import 'lightgallery/css/lg-thumbnail.css';
@import 'lightgallery/css/lg-transitions.css';
@import 'lightgallery/css/lg-video.css';
@import 'lightgallery/css/lg-zoom.css';
</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

import LoadingSpinner from '@/components/LoadingSpinner';
import { ModelObj, ModelFbx, ModelStl, ModelPly, ModelGltf, ModelJson, ModelDae } from 'vue-3d-model';

import Lightgallery from 'lightgallery/vue';
//import lgHash from 'lightgallery/plugins/hash';
import lgZoom from 'lightgallery/plugins/zoom';
import lgRotate from 'lightgallery/plugins/rotate';
import lgVideo from 'lightgallery/plugins/video';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
//import lgPager from 'lightgallery/plugins/pager';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Материал порфтолио | byLex Portfolio'});
	},
	data: () => ({
		work: {},
		
		lightgallerySettings: {
			speed: 500,
			plugins: [lgRotate, lgZoom, lgVideo, lgThumbnail],
			actualSize: false,
			showZoomInOutIcons: true,
			toggleThumb: true,
			allowMediaOverlap: true,
			selector: '.image',
		},
		lightgallerySettings2: {
			speed: 500,
			plugins: [lgRotate, lgZoom, lgVideo, lgThumbnail],
			actualSize: false,
			showZoomInOutIcons: true,
			toggleThumb: true,
			allowMediaOverlap: true,
			selector: '.video',
		},
		lightGallery: null,
		lightGallery2: null,
		
		model3d_rotation: {
			x: 0,
			y: 0,
			z: 0,
		},
		model3d_lights: [
			{
				type: 'HemisphereLight',
				position: { x: 0, y: 1, z: 0 },
				skyColor: 0xffffff, //0xaaaaff,
				groundColor: 0x806060,
				intensity: 0.2,
			},
			{
				type: 'DirectionalLight',
				position: { x: 1, y: 1, z: 1 },
				color: 0xffffff,
				intensity: 0.8,
			},
			{
				type: 'DirectionalLight',
				position: { x: -1, y: -1, z: -1 },
				color: 0xffffff,
				intensity: 0.8,
			},
		],
		
		loading: true,
		error: '',
	}),
	computed: {},
	methods: {
		range: lib.range,
		formatDate: lib.formatDate,
		
		editedVideoSource(video){
			let obj = {source: [{src: video.url, type: 'video/'+video.extension}], attributes: {preload: false, controls: true}};
			return JSON.stringify(obj);
		},
		
		getPoster(images){
			return images.find((el) => el.is_poster);
		},
		getImages(images){
			return images.filter((el) => !el.is_poster);
		},
		
		getModelComponentByExt(ext){
			switch(ext){
				case 'obj':
					return ModelObj;
				case 'fbx':
					return ModelFbx;
				case 'stl':
					return ModelStl;
				case 'ply':
					return ModelPly;
				case 'gltf':
					return ModelGltf;
				case 'json':
					return ModelJson;
				case 'dae':
					return ModelDae;
				default:
					return ModelObj;
			}
		},
		
		async loadData(work_id){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doGetWork(work_id);
			if(ret.success){
				this.work = ret.item;
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		
		// инициализация галереи изображений
		onLightGalleryInit(detail){
			this.lightGallery = detail.instance;
		},
		// инициализация галереи видео
		onLightGalleryInit2(detail){
			this.lightGallery2 = detail.instance;
		},
		
		async onDelete(){
			if(confirm('Вы уверены?')){
				let ret = await this.appModel.doDeleteWork(this.$route.params.id);
				if(ret.success){
					this.$router.push({path: '/works', query: Object.assign({}, this.$route.query, {
						category: this.$route.query.category,
						page: this.$route.query.page||1,
					})});
				} else {
					alert(ret.message);
				}
			}
		},
		
		getDateDuration(obj){
			return (obj.dateend != '0000-00-00 00:00:00' ?
					(lib.dateDiff(this.formatDate(obj.dateadd, 'YYYY-MM-DD'), this.formatDate(obj.dateend, 'YYYY-MM-DD')).days + 1)+
					' '+
					lib.decl1(lib.dateDiff(this.formatDate(obj.dateadd, 'YYYY-MM-DD'), this.formatDate(obj.dateend, 'YYYY-MM-DD')).days + 1)
				:
					(lib.dateDiff(this.formatDate(obj.dateadd, 'YYYY-MM-DD'), this.formatDate(new Date().toUTCString(), 'YYYY-MM-DD')).days + 1)+
					' '+
					lib.decl1(lib.dateDiff(this.formatDate(obj.dateadd, 'YYYY-MM-DD'), this.formatDate(new Date().toUTCString(), 'YYYY-MM-DD')).days + 1)
				);
		},
		number_format(value){
			return lib.number_format(value, 0, '.', ' ');
		},
	},
	async mounted(){
		this.model3d_rotation.x = -Math.PI / 2;
		await this.loadData(this.$route.params.id);
	},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	components: {
		LoadingSpinner,
		ModelObj, ModelFbx, ModelStl, ModelPly, ModelGltf, ModelJson, ModelDae,
		Lightgallery,
	},
}
</script>