<template>
	<div class="bg-struct bg-struct-details bg-img bg-img-19"></div>
	<div class="main-cont">
		<div id="project-sec" class="mb-5">&nbsp;</div>
		<section class="project-sec">
			<div class="d-flex justify-content-between mb-3">
				<h4 style="z-index: 1;">Изменение записи портфолио</h4>
				<div class="btn-group ms-1" role="group" v-if="authModel.isAdmin()">
					<router-link :to="{path: '/works/'+$route.params.id, query: Object.assign({}, this.$route.query, {
						category: this.$route.query.category,
						page: this.$route.query.page||1,
					})}" class="btn btn-secondary">
						<font-awesome-icon :icon="['fas', 'chevron-left']" />
						<span class="ms-2">Назад</span>
					</router-link>
				</div>
			</div>
			<div class="card border-0 shadow-sm text-center" v-if="loading">
				<div class="card-body py-4">
					<loading-spinner color="white" text="ЗАГРУЗКА..." />
				</div>
			</div>
			<div class="card border-0 shadow-sm text-center"  v-if="!loading && !work">
				<div class="card-body py-4">
					<h1>ЗАПИСЬ НЕ НАЙДЕНА</h1>
					<div class="mt-4">
						<router-link :to="{path: '/works', query: Object.assign({}, this.$route.query, {
							category: this.$route.query.category,
							page: this.$route.query.page||1,
						})}" class="btn btn-secondary">
							<font-awesome-icon :icon="['fas', 'chevron-left']" />
							<span class="ms-2">Назад</span>
						</router-link>
					</div>
				</div>
			</div>
			<div class="card border-0 shadow-sm" v-if="!loading && work">
				<form role="form" @submit.prevent="onEdit()">
					<div class="card-body">
						<div class="mb-3">
							<label class="form-label">ID</label>
							<input type="text" class="form-control" :value="work.id" readonly disabled />
						</div>
						<div class="mb-3">
							<label for="workCategoryId" class="form-label">Категория <span class="text-danger">*</span></label>
							<select class="form-select" ref="category_id" id="workCategoryId" v-model="work.category_id" required>
								<option :value="workscat.id" v-for="workscat in worksCategories" :key="workscat.id">{{workscat.name}}</option>
							</select>
						</div>
						<div class="mb-3">
							<label for="workTitle" class="form-label">Название проекта <span class="text-danger">*</span></label>
							<input type="text" class="form-control" ref="title" id="workTitle" v-model="work.title" required autofocus />
						</div>
						<div class="mb-3">
							<label for="workContent" class="form-label">Описание проекта <span class="text-danger">*</span></label>
							<tinymce-editor
								ref="content"
								id="workContent"
								v-model="work.content"
								api-key="no-api-key"
								:init="{
									language_url: '/assets/plugins/tinymce/langs/ru.js',
									language: 'ru',
									plugins: 'accordion advlist anchor autolink autoresize charmap code directionality fullscreen image insertdatetime link lists media nonbreaking pagebreak preview quickbars searchreplace table visualblocks visualchars wordcount',
									toolbar: 'undo redo | styles fontfamily fontsize | bold italic underline strikethrough blockquote checklist bullist numlist backcolor forecolor | link image media hr codeinline | alignleft aligncenter alignright alignjustify | code removeformat',
									mobile: {
										menubar: true,
										//toolbar_mode: 'scrolling',
									},
									setup: (editor) => {
										editor.ui.registry.addButton('codeinline', {
											icon: 'sourcecode',
											tooltip: 'Форматировать как Код',
											onAction: () => editor.execCommand('mceToggleFormat', false, 'code'),
										});
									},
									promotion: false,
								}"
							/>
						</div>
						<div class="mb-3">
							<label for="workTitle" class="form-label">Ссылка на сайт проекта</label>
							<input type="text" class="form-control" ref="title" id="workTitle" v-model="work.site_url" />
						</div>
						<div class="mb-3">
							<label for="workDateadd" class="form-label">Дата и время <span class="text-danger">*</span></label>
							<input type="datetime-local" class="form-control" ref="dateadd" id="workDateadd" v-model="work.dateadd" required />
						</div>
					</div>
					<div class="card-body" v-if="work.models3d.length > 0">
						<label>3D модели</label>
						<div class="row row-gap-3 d-flex justify-content-center">
							<div class="col-6 col-md-2" v-for="model3d in work.models3d" :key="model3d.id">
								<div class="card">
									<div class="card-body text-center">
										<div class="icon">
											<font-awesome-icon :icon="['fas', 'cube']" size="6x" />
										</div>
										<div class="model3d-name">
											<div class="model3d-extension">{{model3d.extension}}</div>
										</div>
										<div class="d-flex gap-2 justify-content-center mt-2">
											<button type="button" class="btn btn-danger" @click="onFileDelete(model3d.pkid)" tooltip="Удалить 3d модель" :disabled="loadingModel3d">
												<font-awesome-icon :icon="['fas', 'trash-can']" />
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="card-body" v-if="work.images.length > 0">
						<label>Изображения</label>
						<div class="images-gallery">
							<lightgallery
								:settings="lightgallerySettings"
								:onInit="onLightGalleryInit"
							>
								<div class="property-image imgbl" v-for="(image, index) in work.images" :key="image.id">
									<a :href="image.url" :data-sub-html="image.descr"  class="image block">
										<img class="img-responsive" :src="image.url_small" :style="{width: (!$device.isMobile ? '150px' : '225px'), height: (!$device.isMobile ? '150px' : '225px')}" />
									</a>
									<div class="d-flex gap-2 justify-content-center">
										<button type="button" class="btn btn-primary" @click="onImageSetPoster(image.pkid)" tooltip="Сделать постером" :disabled="loadingImg" v-if="!image.is_poster">
											<font-awesome-icon :icon="['fas', 'image']" />
										</button>
										<button type="button" class="btn btn-danger" @click="onImageDelete(image.pkid)" tooltip="Удалить изображение" :disabled="loadingImg">
											<font-awesome-icon :icon="['fas', 'trash-can']" />
										</button>
									</div>
								</div>
							</lightgallery>
						</div>
					</div>
					<div class="card-body" v-if="work.videos.length > 0">
						<label>Видео</label>
						<div class="videos-gallery">
							<lightgallery
								:settings="lightgallerySettings2"
								:onInit="onLightGalleryInit2"
							>
								<div class="property-video imgbl" v-for="(video, index) in work.videos" :key="video.id">
									<a :data-video="editedVideoSource(video)" :data-sub-html="video.descr" class="video block">
										<font-awesome-icon :icon="['fas', 'film']" size="6x" />
									</a>
									<div class="d-flex gap-2 justify-content-center">
										<button type="button" class="btn btn-danger" @click="onVideoDelete(video.pkid)" tooltip="Удалить видео" :disabled="loadingVideo">
											<font-awesome-icon :icon="['fas', 'trash-can']" />
										</button>
									</div>
								</div>
							</lightgallery>
						</div>
					</div>
					<div class="card-body">
						<div class="row row-gap-3">
							<div class="col-md-4">
								<div class="bigUploadContainer">
									<h5 class="mt-0">Загрузка 3d моделей</h5>
									<big-upload
										inputFileAccept=".stl,.gltf,.obj,.mtl,.json,.ply,.dae,.fbx"
										:scriptPath="'/api/data/works/'+$route.params.id+'/models3d/upload-chunk'"
										:onSuccess="onBiguploadModel3dSuccess"
									></big-upload>
								</div>
							</div>
							<div class="col-md-4">
								<div class="bigUploadContainer">
									<h5 class="mt-0">Загрузка изображений</h5>
									<big-upload
										inputFileAccept="image/jpeg,image/png,image/gif,image/svg+xml,image/webp,capture=camera"
										:scriptPath="'/api/data/works/'+$route.params.id+'/images/upload-chunk'"
										:onSuccess="onBiguploadImgSuccess"
									></big-upload>
								</div>
							</div>
							<div class="col-md-4">
								<div class="bigUploadContainer">
									<h5 class="mt-0">Загрузка видео</h5>
									<big-upload
										inputFileAccept="video/*,capture=camcorder,capture=filesystem"
										:scriptPath="'/api/data/works/'+$route.params.id+'/videos/upload-chunk'"
										:onSuccess="onBiguploadVideoSuccess"
									></big-upload>
								</div>
							</div>
						</div>
					</div>
					<div class="card-footer">
						<div class="d-grid gap-2 d-md-block text-center">
							<button type="submit" class="btn btn-primary">
								<font-awesome-icon :icon="['far', 'floppy-disk']" />
								<span class="ms-2">Сохранить</span>
							</button>
							<button type="button" class="btn btn-danger ms-md-2" @click="onDelete()">
								<font-awesome-icon :icon="['fas', 'trash-can']" />
								<span class="ms-2">Удалить</span>
							</button>
						</div>
					</div>
					<div class="card-body" v-if="error">
						<div class="alert alert-warning alert-dismissible mb-0" role="alert">
							{{error}}
							<button type="button" class="btn-close" aria-label="Close" @click="error = ''"></button>
						</div>
					</div>
				</form>
			</div>
		</section>
	</div>
</template>

<style lang="sass" scoped>
@import 'lightgallery/css/lightgallery.css';
@import 'lightgallery/css/lg-rotate.css';
@import 'lightgallery/css/lg-pager.css';
@import 'lightgallery/css/lg-thumbnail.css';
@import '@/assets/css/lg-thumbnail.css';
@import 'lightgallery/css/lg-transitions.css';
@import 'lightgallery/css/lg-video.css';
@import 'lightgallery/css/lg-zoom.css';

.bigUploadContainer {
	max-width: 350px;
	padding: 20px;
	margin: 0 auto;
	border: 1px solid #555;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	-moz-box-shadow: 0 1px 2px rgba(0,0,0,.05);
	-webkit-box-shadow: 0 1px 2px rgba(0,0,0,.05);
	box-shadow: 0 1px 2px rgba(0,0,0,.05);
	text-align: center;
}

.images-gallery {
	margin: 0;
	padding: 0;
	text-align: center;
}
.images-gallery .property-image {
	display: inline-block;
	margin: 10px 10px;
}
.images-gallery .property-image a.image,
.images-gallery .property-image a.btn {
	display: block;
}
.images-gallery .property-image a.image {
	padding-bottom: 5px;
}

.videos-gallery {
	margin: 0;
	padding: 0;
	text-align: center;
}
.videos-gallery .property-video {
	display: inline-block;
	margin: 10px 10px;
}
.videos-gallery .property-video a.video,
.videos-gallery .property-video a.btn {
	display: block;
}
.videos-gallery .property-video a.video {
	padding-bottom: 5px;
}
</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

import LoadingSpinner from '@/components/LoadingSpinner';
import BigUpload from '@/components/BigUpload';

// TinyMCE
import 'tinymce/tinymce';
import 'tinymce/icons/default/icons';
import 'tinymce/themes/silver/theme';
import 'tinymce/models/dom/model';
import 'tinymce/skins/ui/oxide-dark/skin.css';

// TinyMCE plugins
// https://www.tiny.cloud/docs/tinymce/6/plugins/
import 'tinymce/plugins/accordion/plugin';
import 'tinymce/plugins/advlist/plugin';
import 'tinymce/plugins/anchor/plugin';
import 'tinymce/plugins/autolink/plugin';
import 'tinymce/plugins/autoresize/plugin';
import 'tinymce/plugins/charmap/plugin';
import 'tinymce/plugins/code/plugin';
import 'tinymce/plugins/directionality/plugin';
import 'tinymce/plugins/fullscreen/plugin';
import 'tinymce/plugins/image/plugin';
import 'tinymce/plugins/insertdatetime/plugin';
import 'tinymce/plugins/link/plugin';
import 'tinymce/plugins/lists/plugin';
import 'tinymce/plugins/media/plugin';
import 'tinymce/plugins/nonbreaking/plugin';
import 'tinymce/plugins/pagebreak/plugin';
import 'tinymce/plugins/preview/plugin';
import 'tinymce/plugins/quickbars/plugin';
import 'tinymce/plugins/searchreplace/plugin';
import 'tinymce/plugins/table/plugin';
import 'tinymce/plugins/visualblocks/plugin';
import 'tinymce/plugins/visualchars/plugin';
import 'tinymce/plugins/wordcount/plugin';

import TinymceEditor from '@tinymce/tinymce-vue';

import Lightgallery from 'lightgallery/vue';
//import lgHash from 'lightgallery/plugins/hash';
import lgZoom from 'lightgallery/plugins/zoom';
import lgRotate from 'lightgallery/plugins/rotate';
import lgVideo from 'lightgallery/plugins/video';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
//import lgPager from 'lightgallery/plugins/pager';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Изменение записи портфолио | Админ-панель | byLex Portfolio'});
	},
	data: () => ({
		worksCategories: [],
		
		work: {},
		
		lightgallerySettings: {
			speed: 500,
			plugins: [lgRotate, lgZoom, lgVideo, lgThumbnail],
			actualSize: false,
			showZoomInOutIcons: true,
			toggleThumb: true,
			allowMediaOverlap: true,
			selector: '.image',
		},
		lightgallerySettings2: {
			speed: 500,
			plugins: [lgRotate, lgZoom, lgVideo, lgThumbnail],
			actualSize: false,
			showZoomInOutIcons: true,
			toggleThumb: true,
			allowMediaOverlap: true,
			selector: '.video',
		},
		lightGallery: null,
		lightGallery2: null,
		
		loadingImg: false,
		loadingVideo: false,
		loadingModel3d: false,
		loading: true,
		error: '',
	}),
	watch: {
		'work.images': {
			handler(val, oldVal){
				this.$nextTick(() => {
					if(this.lightGallery) this.lightGallery.refresh();
				});
			},
			deep: true,
		},
		'work.videos': {
			handler(val, oldVal){
				this.$nextTick(() => {
					if(this.lightGallery2) this.lightGallery2.refresh();
				});
			},
			deep: true,
		},
	},
	computed: {},
	methods: {
		contains: lib.contains,
		formatDate: lib.formatDate,
		formatDateUtc: lib.formatDateUtc,
		
		editedVideoSource(video){
			let obj = {source: [{src: video.url, type: 'video/'+video.extension}], attributes: {preload: false, controls: true}};
			return JSON.stringify(obj);
		},
		
		// инициализация галереи изображений
		onLightGalleryInit(detail){
			this.lightGallery = detail.instance;
		},
		// инициализация галереи видео
		onLightGalleryInit2(detail){
			this.lightGallery2 = detail.instance;
		},
		
		async loadWorksCategories(){
			this.error = '';
			let ret = await this.appModel.doGetWorksCategories();
			if(ret.success){
				this.worksCategories = ret.list;
			} else {
				this.error = ret.message;
			}
		},
		async loadImages(){
			this.error = '';
			let ret = await this.appModel.doGetWorkImages(this.$route.params.id);
			if(ret.success){
				this.work.images = ret.images;
			} else {
				this.error = ret.message;
			}
		},
		async loadVideos(){
			this.error = '';
			let ret = await this.appModel.doGetWorkVideos(this.$route.params.id);
			if(ret.success){
				this.work.videos = ret.videos;
			} else {
				this.error = ret.message;
			}
		},
		async loadModels3d(){
			this.error = '';
			let ret = await this.appModel.doGetWorkModels3d(this.$route.params.id);
			if(ret.success){
				this.work.models3d = ret.models3d;
			} else {
				this.error = ret.message;
			}
		},
		async loadData(){
			this.error = '';
			let ret = await this.appModel.doGetWork(this.$route.params.id);
			this.work = ret.item;
			if(this.work){
				await this.loadImages();
				await this.loadVideos();
				await this.loadModels3d();
			}
		},
		
		async onImageSetPoster(image_pkid){
			if(confirm('Вы уверены?')){
				this.loadingImg = true;
				this.error = '';
				let ret = await this.appModel.doChangeWorkPoster(this.$route.params.id, image_pkid);
				if(ret.success){
					await this.loadImages();
				} else {
					this.error = ret.message;
				}
				this.loadingImg = false;
			}
		},
		async onImageDelete(image_pkid){
			if(confirm('Вы уверены?')){
				this.loadingImg = true;
				this.error = '';
				let ret = await this.appModel.doDeleteWorkImage(this.$route.params.id, image_pkid);
				if(ret.success){
					await this.loadImages();
				} else {
					this.error = ret.message;
				}
				this.loadingImg = false;
			}
		},
		async onVideoDelete(video_pkid){
			if(confirm('Вы уверены?')){
				this.loadingVideo = true;
				this.error = '';
				let ret = await this.appModel.doDeleteWorkVideo(this.$route.params.id, video_pkid);
				if(ret.success){
					await this.loadVideos();
				} else {
					this.error = ret.message;
				}
				this.loadingVideo = false;
			}
		},
		async onModel3dDelete(model3d_pkid){
			if(confirm('Вы уверены?')){
				this.loadingModel3d = true;
				this.error = '';
				let ret = await this.appModel.doDeleteWorkModel3d(this.$route.params.id, model3d_pkid);
				if(ret.success){
					await this.loadModels3d();
				} else {
					this.error = ret.message;
				}
				this.loadingModel3d = false;
			}
		},
		
		async onEdit(){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doEditWork(this.$route.params.id, {
				category_id: this.work.category_id,
				type: this.work.type,
				title: this.work.title,
				content: this.work.content,
				site_url: this.work.site_url,
				dateadd: this.formatDateUtc(this.work.dateadd, 'YYYY-MM-DDTHH:mm'),
			});
			if(!ret.success){
				this.error = ret.message;
			}
			this.loading = false;
		},
		async onDelete(){
			if(confirm('Вы уверены?')){
				this.loading = true;
				let ret = await this.appModel.doDeleteWork(this.$route.params.id);
				if(ret.success){
					this.$router.push({path: '/works', query: Object.assign({}, this.$route.query, {
						category: this.$route.query.category,
						page: this.$route.query.page||1,
					})});
				} else {
					this.error = ret.message;
				}
				this.loading = false;
			}
		},
		
		// успешная загрузка изображения
		async onBiguploadImgSuccess(response){
			if(response.success || false){
				this.loadImages();
			}
		},
		// успешная загрузка видео
		async onBiguploadVideoSuccess(response){
			if(response.success || false){
				this.loadVideos();
			}
		},
		// успешная загрузка 3d модели
		async onBiguploadModel3dSuccess(response){
			if(response.success || false){
				this.loadModels3d();
			}
		},
	},
	async mounted(){
		this.loading = true;
		await this.loadWorksCategories();
		await this.loadData();
		if(this.work){
			this.work.dateadd = this.formatDate(this.work.dateadd, 'YYYY-MM-DDTHH:mm');
		}
		this.loading = false;
	},
	components: {
		LoadingSpinner,
		BigUpload,
		Lightgallery,
		TinymceEditor,
	},
}
</script>