import { createRouter, createWebHistory } from 'vue-router';

import lib from '@/lib';

import Layout from '@/layouts/Layout';
import LayoutAdmin from '@/layouts/LayoutAdmin';

import Home from '@/pages/Home';

import Works from '@/pages/Works';
import WorksItem from '@/pages/WorksItem';

import Blog from '@/pages/Blog';
import BlogItem from '@/pages/BlogItem';

import Login from '@/pages/Login';
import AdminMain from '@/pages/admin/Main';
import AdminWorksCategories from '@/pages/admin/WorksCategories';
import AdminWorksCategoriesAdd from '@/pages/admin/WorksCategoriesAdd';
import AdminWorksCategoriesEdit from '@/pages/admin/WorksCategoriesEdit';
import AdminWorksAdd from '@/pages/admin/WorksAdd';
import AdminWorksEdit from '@/pages/admin/WorksEdit';
import AdminBlogCategories from '@/pages/admin/BlogCategories';
import AdminBlogCategoriesAdd from '@/pages/admin/BlogCategoriesAdd';
import AdminBlogCategoriesEdit from '@/pages/admin/BlogCategoriesEdit';
import AdminBlogAdd from '@/pages/admin/BlogAdd';
import AdminBlogEdit from '@/pages/admin/BlogEdit';

import Test1 from '@/pages/Test1';
import Test2 from '@/pages/Test2';

import PageNotFound from '@/pages/PageNotFound';

const routes = [
	/*{
		path: '/',
		redirect: '/index',
	},*/
	{
		path: '/',
		component: Layout,
		children: [
			{
				name: 'home',
				path: '',
				component: Home,
			},
			{
				name: 'works',
				path: '/works',
				component: Works,
			},
			{
				name: 'works-item',
				path: '/works/:id',
				component: WorksItem,
			},
			{
				name: 'blog',
				path: '/blog',
				component: Blog,
			},
			{
				name: 'blog-item',
				path: '/blog/:id',
				component: BlogItem,
			},
			{
				name: 'login',
				path: '/login',
				component: Login,
				meta: {
					guest: true,
				},
			},
			
			{
				name: 'test1',
				path: '/test1',
				component: Test1,
			},
			{
				name: 'test2',
				path: '/test2',
				component: Test2,
			},
		],
	},
	{
		path: '/admin',
		component: LayoutAdmin,
		children: [
			{
				name: 'admin-main',
				path: '',
				component: AdminMain,
				meta: {
					is_admin: true,
				},
			},
			{
				name: 'admin-works-categories',
				path: '/admin/works-categories',
				component: AdminWorksCategories,
				meta: {
					is_admin: true,
				},
			},
			{
				name: 'admin-works-categories-add',
				path: '/admin/works-categories/add',
				component: AdminWorksCategoriesAdd,
				meta: {
					is_admin: true,
				},
			},
			{
				name: 'admin-works-categories-edit',
				path: '/admin/works-categories/:id',
				component: AdminWorksCategoriesEdit,
				meta: {
					is_admin: true,
				},
			},
			{
				name: 'admin-works-add',
				path: '/admin/works/add',
				component: AdminWorksAdd,
				meta: {
					is_admin: true,
				},
			},
			{
				name: 'admin-works-edit',
				path: '/admin/works/:id',
				component: AdminWorksEdit,
				meta: {
					is_admin: true,
				},
			},
			{
				name: 'admin-blog-categories',
				path: '/admin/blog-categories',
				component: AdminBlogCategories,
				meta: {
					is_admin: true,
				},
			},
			{
				name: 'admin-blog-categories-add',
				path: '/admin/blog-categories/add',
				component: AdminBlogCategoriesAdd,
				meta: {
					is_admin: true,
				},
			},
			{
				name: 'admin-blog-categories-edit',
				path: '/admin/blog-categories/:id',
				component: AdminBlogCategoriesEdit,
				meta: {
					is_admin: true,
				},
			},
			{
				name: 'admin-blog-add',
				path: '/admin/blog/add',
				component: AdminBlogAdd,
				meta: {
					is_admin: true,
				},
			},
			{
				name: 'admin-blog-edit',
				path: '/admin/blog/:id',
				component: AdminBlogEdit,
				meta: {
					is_admin: true,
				},
			},
		],
	},
	{
		name: 'logout',
		path: '/logout',
		beforeEnter(to, from, next){
			storeInstance.state.app.auth.doLogout().then(() => {
				next({path: '/login'});
			});
		},
	},
	
	{
		name: 'page-not-found',
		path: '/:pathMatch(.*)*',
		component: Layout,
		children: [
			{
				path: '',
				component: PageNotFound,
			},
		],
	},
	{
		name: 'page-not-found',
		path: '/:pathMatch(.*)',
		component: Layout,
		children: [
			{
				path: '',
				component: PageNotFound,
			},
		],
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach((to, from, next) => {
	storeInstance.state.app.auth.doCheckAuth().then(() => {
		if(to.matched.some(record => record.meta.requiresAuth)){
			if(!storeInstance.state.app.auth.isAuth()){
				next({
					//path: '/login',
					path: '/',
					params: {
						nextUrl: to.fullPath,
					},
				})
			} else {
				if(to.matched.some(record => record.meta.is_admin)){
					if(storeInstance.state.app.auth.isAdmin()){
						next()
					} else {
						next('/')
					}
				} else {
					next()
				}
			}
		} else if(to.matched.some(record => record.meta.guest)){
			if(storeInstance.state.app.auth.isAuth()){
				next('/')
			} else {
				next()
			}
		} else {
			next() 
		}
	});
});
/*// Не позволяет авторизованному пользователю перейти на страницу логина и перекидывает его на главную страницу
router.beforeEach((to, from, next) => {
	if (to.path == '/login' && storeInstance.state.app.auth.isAuth()) next({path: '/'})
  	else next()
})*/

export default router;