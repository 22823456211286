<template>
	<div class="bg-struct bg-struct-details bg-img bg-img-19"></div>
	<div class="main-cont">
		<div class="row align-items-center">
			<div class="col-md-4 offset-md-4">
				<div class="card bg-dark border-0 shadow-sm">
					<div class="card-body text-center">
						<div class="row row-cols-1 g-3">
							<div class="col">
								<router-link :to="{path: '/admin/works-categories'}" class="card text-decoration-none h-100">
									<div class="card-body">
										<h5 class="card-title mb-0">Категории портфолио</h5>
									</div>
								</router-link>
							</div>
							<div class="col">
								<router-link :to="{path: '/admin/blog-categories'}" class="card text-decoration-none h-100">
									<div class="card-body">
										<h5 class="card-title mb-0">Категории блога</h5>
									</div>
								</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css">

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Админ-панель | byLex Portfolio'});
	},
	data: () => ({
		
	}),
	computed: {},
	methods: {}
}
</script>