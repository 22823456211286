import axios from "axios";
import moment from 'moment-timezone';
import format from 'moment-duration-format';
import app_mixin from "@/mixin";

let _ua = navigator.userAgent.toLowerCase();

const lib = {
	is_frame: false,
	
	mixins: [
		app_mixin,
	],

	/**
	 * Create store from model
	 */
	createStoreFromModel(class_name){
		let state = new class_name()
		
		const getters = {};
		const actions = {};
		const mutations = {};
		
		return {
			namespaced: true,
			state,
			getters,
			actions,
			mutations,
		};
	},
	
	/**
	 * Add mixin
	 */
	addMixin(mixin){
		this.mixins.push(mixin);
	},
	
	/**
	 * Check is exists
	 */
	isExists(value){
		return value != null && value != undefined;
	},
	
	/**
	 * Получает cookie по названию
	 */
	getCookie(name){
		var matches = document.cookie.match(
			new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\/\+^])/g, "\$1") + "=([^;]*)"	)
		);
		return matches ? decodeURIComponent(matches[1]) : null; 
	},
	
	/**
	 * Устанавливает cookie
	 */
	setCookie(name, value, expires, path, domain, secure){
		//console.log("setCookie " + name);
		if(expires == undefined) expires = 7 * 24 * 60 * 60;
		if(path == undefined) path = "/";
		var cookie_string = name+'='+escape(value);
		//if(expires) cookie_string += '; expires='+expires.toUTCString();
		if(path) cookie_string += '; path='+escape(path);
		if(domain) cookie_string += '; domain='+escape(domain);
		if(secure) cookie_string += '; secure';
		document.cookie = cookie_string;
	},

	/**
	 * Удаляет куки
	 */
	deleteCookie(name){
		this.setCookie(name, '', 0);
	},
	
	/**
	 * Парсит JWT токен на основе строки
	 */
	parseJwt(token){
		let base64Url = token.split(".")[1];
		let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
		
		//console.log("token", token);
		//console.log("base64", base64);
		
		try{
			if(typeof atob != "undefined") base64 = atob(base64);
			else base64 = (Buffer.from(base64, 'base64').toString());
			
			//console.log("atob(base64)", base64);
			
			let jsonPayload = decodeURIComponent(
				base64
				.split("")
				.map(function (c) {
					return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
				})
				.join("")
			);
			
			//console.log("jsonPayload", jsonPayload);
			
			return JSON.parse(jsonPayload);
		}
		catch (e){
			
		}
		
		return null;
	},
	
	nl2br(str){
		return str.replace(/([^>])\n/g, '$1<br/>');
	},
	
	urlGetAddArr(url, arr, force){
		var url = new String(url);
		var pos = url.indexOf('#');
		if((typeof force) == 'undefined') force = 1;
		if(pos != -1){
			url = url.substring(0, pos);
		}
		for(var param in arr){
			var value = encodeURIComponent(arr[param]);
			var val = new RegExp('(\\?|\\&)'+param+'=.*?(?=(&|$))'), qstring = /\?.+$/;
			param = encodeURIComponent(param);
			if(val.test(url)){
				if(force == 1){
					if(value != ''){
						url = url.replace(val, '$1'+param+'='+value);
					} else {
						url = url.replace(val, '$1');
					}
				}
			} else if(qstring.test(url)){
				if(value != ''){
					url += '&'+param+'='+value;
				} else {
					url = url;
				}
			} else {
				if(value != ''){
					url += '?'+param+'='+value;
				} else {
					url = url;
				}
			}
		}
		return url;
	},
	
	urlGetAdd(url){
		var arr = {};
		var param, value, j = 0;
		for(var i = 1; i < arguments.length; i++){
			if(j == 0){
				param = arguments[i];
			} else {
				value = arguments[i];
				arr[param] = value;
				j = -1;
			}
			j++;
		}
		return this.urlGetAddArr(url, arr, 1);
	},
	
	urlGetAdd2(url){
		var arr = {};
		var param, value, j = 0;
		for(var i = 1; i < arguments.length; i++){
			if(j == 0){
				param = arguments[i];
			} else {
				value = arguments[i];
				if((typeof arr[param]) == 'undefined'){
					arr[param] = value;
				}
				j = -1;
			}
			j++;
		}
		return this.urlGetAddArr(url, arr, 0);
	},
	
	urlGetNormal(url){
		var url = new String(url);
		var s1 = new RegExp("\\&+", "g");
		var s2 = new RegExp("\\?\\&+", "g");
		var s3 = new RegExp("\\&$", "g");
		url = url.replace(s1, '&');
		url = url.replace(s2, '?');
		url = url.replace(s3, '');
		return url;
	},
	
	isUrl(s){
		var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
		return regexp.test(s);
	},
	
	/**
	 * Добавляет value в FormData
	 */
	appendFormData(form, value, key){
		if (key == undefined){
			key = "";
		}
		if (typeof value === 'object'){
			for (let i in value){
				let v = value[i];
				if (key == ''){
					this.appendFormData(form, v, i);
				} else {
					this.appendFormData(form, v, key + '[' + i + ']');
				}
			}
		} else {
			form.append(key, value);
		}
	},
	
	/**
	 * Создает blob файл из base64 string
	 */
	createBlobFromBase64: function (b64Data, contentType = '', sliceSize = 512){
		const byteCharacters = atob(b64Data);
		const byteArrays = [];
		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);
			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}
			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}
		const blob = new Blob(byteArrays, {type: contentType});
		return blob;
	},
	
	/* my */
	
	microtime(get_as_float){
		// discuss at: http://phpjs.org/functions/microtime/
		// original by: Paulo Freitas
		// example 1: timeStamp = microtime(true);
		// example 1: timeStamp > 1000000000 && timeStamp < 2000000000
		// returns 1: true
		let now = new Date().getTime() / 1000;
		let s = parseInt(now, 10);
		return (get_as_float) ? now : (Math.round((now - s) * 1000) / 1000) + ' ' + s;
	},
	
	mt_rand(min, max){
		//return Math.floor(Math.random() * (max - min) + min);
		return Math.floor(Math.random() * ((max + 1) - min) + min);
	},
	
	genId(){
		return Math.floor(this.microtime(1) * 10000)+''+this.mt_rand(10, 99);
	},
	
	/**
	 * Склонение слов (метод 1)
	 */
	decl1(intval, expr){
		if(intval == null || typeof(intval) == 'undefined') intval = 1;
		if(expr == null || typeof(expr) == 'undefined') expr = ['день', 'дня', 'дней'];
		
		intval = parseInt(intval);
		let count = intval % 100;
		let result = '';
		if(count >= 5 && count <= 20){
			result = expr[2];
		} else {
			count = count % 10;
			if(count == 1){
				result = expr[0];
			} else if(count >= 2 && count <= 4){
				result = expr[1];
			} else {
				result = expr[2];
			}
		}
		return result;
	},
	
	/**
	 * Склонение слов (метод 2)
	 */
	decl2(intval, expr){
		if(intval == null || typeof(intval) == 'undefined') intval = 1;
		if(expr == null || typeof(expr) == 'undefined') expr = ['день', 'дня', 'дней'];
		
		let k = intval % 10 == 1 && intval % 100 != 11 ? 0 : (intval % 10 >= 2 && intval % 10 <= 4 && (intval % 100 < 10 || intval % 100 >= 20) ? 1 : 2);
		return expr[k];
	},
	
	/**
	 * ex: alert(dateDiff('2013-01-10 00:10', '2013-01-11 00:09'));
	 * ex: alert(dateDiff('2013-04-10', '2013-10-20'));
	 * date1 - ot, date2 - do
	 */
	dateDiff(date1, date2){
		date1 = new Date(date1);
		date2 = new Date(date2);
		
		let milliseconds = date2.getMilliseconds() - date1.getMilliseconds();
		
		if(milliseconds < 0){
			milliseconds += 1000;
			date2.setSeconds(date2.getSeconds() - 1);
		}
		
		let seconds = date2.getSeconds() - date1.getSeconds();
		
		if(seconds < 0){
			seconds += 60;
			date2.setMinutes(date2.getMinutes() - 1);
		}
		
		let minutes = date2.getMinutes() - date1.getMinutes();
		
		if(minutes < 0){
			minutes += 60;
			date2.setHours(date2.getHours() - 1);
		}
		
		let hours = date2.getHours() - date1.getHours();
		
		if(hours < 0){
			hours += 24;
			date2.setDate(date2.getDate() - 1);
		}
		
		let days = date2.getDate() - date1.getDate();
		
		if(days < 0){
			days += new Date(date2.getFullYear(), date2.getMonth() - 1, 0).getDate() + 1;
			date2.setMonth(date2.getMonth() - 1);
		}

		let months = date2.getMonth() - date1.getMonth();
		
		if(months < 0){
			months += 12;
			date2.setFullYear(date2.getFullYear() - 1);
		}
		
		let years = date2.getFullYear() - date1.getFullYear();
		
		return {
			years: years,
			months: months,
			days: days,
			hours: hours,
			minutes: minutes,
			seconds: seconds,
			milliseconds: milliseconds
		};
	},
	
	deleteCookieOld(name){
		this.setCookie(name, "", -1);
	},
	
	setCookieOld(name, value, days){
		let expires;
		if(days){
			let date = new Date();
			date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
			expires = "; expires=" + date.toGMTString();
		}
		else expires = "";
		document.cookie = name + "=" + value + expires + "; path=/";
	},
	
	getCookieOld(name){
		let nameEQ = name + "=";
		let ca = document.cookie.split(';');
		for(let i = 0;i < ca.length; i++){
			let c = ca[i];
			while(c.charAt(0) == ' ') c = c.substring(1, c.length);
			if(c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
		}
		return null;
	},
	
	splitrazr(text, razr, sep){
		razr = razr || 4;
		sep = sep || ' ';
		const spl = (xs, s) => xs.length ? [xs.slice(0, s), ...spl(xs.slice(s), s)] : []
		return spl(text, razr).join(sep);
	},
	
	copyToClipboard(text){
		const elem = document.createElement('textarea');
		elem.value = text;
		document.body.appendChild(elem);
		elem.select();
		document.execCommand('copy');
		document.body.removeChild(elem);
	},
	
	localStorageSet(key, value, ttl = 60 * 60 * 24 * 1000){
		const now = new Date();

		// `item` is an object which contains the original value
		// as well as the time when it's supposed to expire
		const item = {
			value: value,
			expiry: now.getTime() + ttl,
		}
		localStorage.setItem(key, JSON.stringify(item));
	},
	
	localStorageGet(key){
		const itemStr = localStorage.getItem(key);

		// if the item doesn't exist, return null
		if(!itemStr){
			return null;
		}

		const item = JSON.parse(itemStr);
		const now = new Date();

		// compare the expiry time of the item with the current time
		if(now.getTime() > item.expiry){
			// If the item is expired, delete the item from storage
			// and return null
			localStorage.removeItem(key);
			return null;
		}
		return item.value;
	},
	
	localStorageRemove(key){
		localStorage.removeItem(key);
	},
	
	contains(text, searchtext, or){
		or = or ?? false;
		if(searchtext.join){
			if(or){
				var ret = false;
				searchtext.forEach(function(el, i, arr){
					if(~text.indexOf(el)){
						ret = true;
						return false;
					}
				});
				return ret;
			} else {
				var ret = true;
				searchtext.forEach(function(el, i, arr){
					if(text.indexOf(el) == -1){
						ret = false;
					}
				});
				return ret;
			}
		} else {
			return text.indexOf(searchtext) > -1;
		}
	},
	
	number_format(number, decimals, dec_point, thousands_sep){
		// http://kevin.vanzonneveld.net
		// +   original by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
		// +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
		// +     bugfix by: Michael White (http://getsprink.com)
		// +     bugfix by: Benjamin Lupton
		// +     bugfix by: Allan Jensen (http://www.winternet.no)
		// +    revised by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
		// +     bugfix by: Howard Yeend
		// +    revised by: Luke Smith (http://lucassmith.name)
		// +     bugfix by: Diogo Resende
		// +     bugfix by: Rival
		// +      input by: Kheang Hok Chin (http://www.distantia.ca/)
		// +   improved by: davook
		// +   improved by: Brett Zamir (http://brett-zamir.me)
		// +      input by: Jay Klehr
		// +   improved by: Brett Zamir (http://brett-zamir.me)
		// +      input by: Amir Habibi (http://www.residence-mixte.com/)
		// +     bugfix by: Brett Zamir (http://brett-zamir.me)
		// +   improved by: Theriault
		// +      input by: Amirouche
		// +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
		// *     example 1: number_format(1234.56);
		// *     returns 1: '1,235'
		// *     example 2: number_format(1234.56, 2, ',', ' ');
		// *     returns 2: '1 234,56'
		// *     example 3: number_format(1234.5678, 2, '.', '');
		// *     returns 3: '1234.57'
		// *     example 4: number_format(67, 2, ',', '.');
		// *     returns 4: '67,00'
		// *     example 5: number_format(1000);
		// *     returns 5: '1,000'
		// *     example 6: number_format(67.311, 2);
		// *     returns 6: '67.31'
		// *     example 7: number_format(1000.55, 1);
		// *     returns 7: '1,000.6'
		// *     example 8: number_format(67000, 5, ',', '.');
		// *     returns 8: '67.000,00000'
		// *     example 9: number_format(0.9, 0);
		// *     returns 9: '1'
		// *    example 10: number_format('1.20', 2);
		// *    returns 10: '1.20'
		// *    example 11: number_format('1.20', 4);
		// *    returns 11: '1.2000'
		// *    example 12: number_format('1.2000', 3);
		// *    returns 12: '1.200'
		// *    example 13: number_format('1 000,50', 2, '.', ' ');
		// *    returns 13: '100 050.00'
		// Strip all characters but numerical ones.
		number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
		var n = !isFinite(+number) ? 0 : +number,
			prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
			sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
			dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
			s = '',
			toFixedFix = function (n, prec) {
			var k = Math.pow(10, prec);
			return '' + Math.round(n * k) / k;
			};
		// Fix for IE parseFloat(0.55).toFixed(0) = 0;
		s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
		if (s[0].length > 3) {
			s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
		}
		if ((s[1] || '').length < prec) {
			s[1] = s[1] || '';
			s[1] += new Array(prec - s[1].length + 1).join('0');
		}
		return s.join(dec);
	},
	
	formatSizeUnits(bytes, a = 2, arr = ['B', 'KiB', 'MiB', 'GiB', 'TiB']){
		if(bytes >= 1099511627776){
			bytes = this.number_format(bytes / 1099511627776, a)+' '+(arr[4]||'TiB');
		} else if(bytes >= 1073741824){
			bytes = this.number_format(bytes / 1073741824, a)+' '+(arr[3]||'GiB');
		} else if(bytes >= 1048576){
			bytes = this.number_format(bytes / 1048576, a)+' '+(arr[2]||'MiB');
		} else if(bytes >= 1024){
			bytes = this.number_format(bytes / 1024, a)+' '+(arr[1]||'KiB');
		} else if(bytes >= 1){
			bytes = bytes+' '+(arr[0]||'B');
		} else {
			bytes = '0 '+(arr[0]||'B');
		}
		return bytes;
	},
	
	webkit: (_ua.indexOf('webkit') > -1),
	
	browser: {
		version: (_ua.match( /.+(?:me|ox|on|rv|it|era|ie)[\/: ]([\d.]+)/ ) || [0,'0'])[1],
		opera: /opera/i.test(_ua),
		msie: (/msie/i.test(_ua) && !/opera/i.test(_ua)),
		msie6: (/msie 6/i.test(_ua) && !/opera/i.test(_ua)),
		msie7: (/msie 7/i.test(_ua) && !/opera/i.test(_ua)),
		msie8: (/msie 8/i.test(_ua) && !/opera/i.test(_ua)),
		msie9: (/msie 9/i.test(_ua) && !/opera/i.test(_ua)),
		mozilla: /firefox/i.test(_ua),
		chrome: /chrome/i.test(_ua),
		safari: (!(/chrome/i.test(_ua)) && /webkit|safari|khtml/i.test(_ua)),
		iphone: /iphone/i.test(_ua),
		ipod: /ipod/i.test(_ua),
		iphone4: /iphone.*OS 4/i.test(_ua),
		ipod4: /ipod.*OS 4/i.test(_ua),
		ipad: /ipad/i.test(_ua),
		android: /android/i.test(_ua),
		bada: /bada/i.test(_ua),
		mobile: /iphone|ipod|ipad|opera mini|opera mobi|iemobile|android/i.test(_ua),
		msie_mobile: /iemobile/i.test(_ua),
		safari_mobile: /iphone|ipod|ipad/i.test(_ua),
		opera_mobile: /opera mini|opera mobi/i.test(_ua),
		opera_mini: /opera mini/i.test(_ua),
		mac: /mac/i.test(_ua)
	},
	
	isWebKit(){
		return this.webkit;
	},
	
	expand(destination, source){
		for(var property in source){
			if(!destination.hasOwnProperty(property)){
				destination[property] = source[property];
			}
		}
		return true;
	},
	
	inherit(child, super_class){
		if(typeof child.prototype.__proto__ == 'undefined'){
			this.expand(child.prototype, super_class.prototype);
		} else {
			child.prototype.__proto__ = super_class.prototype;
		}
	},
	
	/**
	 * Генерация массива по указанному количеству (при количестве 0 вернет пустой массив)
	 * пример вывода с количеством 10 => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
	 * 
	 * @param {*} count количество создаваемых элементов массива
	 * @return array
	 */
	genArrayByCount(count){
		return [...Array(count).keys()];
	},
	
	/**
	 * Обрезка текста до указанной длины
	 * 
	 * @param {*} text текст, который будет урезан до указанной длины
	 * @param {*} length максимальная длина текста, после чего остальной текст будет заменен постфиксом
	 * @param {*} postfix постфикс, который будет добавлен после обрезанного текста
	 * @returns string
	 */
	truncate(text, length, postfix = '...'){
		if(text && text.length > length){
			text = text.substring(0, length) + postfix;
		}
		return text;
	},
	
	/**
	 * Получение процента, зная число и максимальное значение
	 * 
	 * @param {*} val текущее значение
	 * @param {*} max максимальное значение
	 * @returns 
	 */
	getPercent(val, max){
		val = val || 0;
		max = max || 0;
		let ret = ((val / max * 100)||0).toFixed(1);
		return ret < 0 ? 0 : (ret > 100 ? 100 : ret);
	},
	
	/**
	 * Преобразование даты формата 0000-00-00 00:00:00 в дату нужного формата
	 * 
	 * @param {*} date дата в формате '0000-00-00 00'
	 * @param {*} format формат даты в виде 'DD.MM.YYYY HH:mm:ss'
	 * @param {*} timezone зона времени типа Asia/Almaty
	 * @returns 
	 */
	formatDate(date, format = 'DD.MM.YYYY HH:mm:ss', timezone = 'Asia/Almaty'){
		return moment.utc(date).tz(timezone).format(format);
	},
	
	/**
	 * Преобразование даты формата 0000-00-00 00:00:00 в дату нужного формата
	 * 
	 * @param {*} date дата в формате '0000-00-00 00'
	 * @param {*} format формат даты в виде 'DD.MM.YYYY HH:mm:ss'
	 * @param {*} timezone зона времени типа Asia/Almaty
	 * @returns 
	 */
	formatDateUtc(date, format = 'DD.MM.YYYY HH:mm:ss', timezone = 'Asia/Almaty'){
		return moment.tz(date, timezone).utc().format(format);
	},
	
	/**
	 * Преобразование даты типа timestamp в дату нужного формата
	 * 
	 * @param {*} timestamp дата типа timestamp
	 * @param {*} format формат даты в виде 'DD.MM.YYYY HH:mm:ss'
	 * @param {*} timezone зона времени типа Asia/Almaty
	 * @returns 
	 */
	formatUnixDate(timestamp, format = 'DD.MM.YYYY HH:mm:ss', timezone = 'Asia/Almaty'){
		return moment.unix(timestamp).utc().tz(timezone).format(format);
	},
	
	/**
	 * Преобразование даты типа timestamp в дату нужного формата
	 * 
	 * @param {*} timestamp дата типа timestamp
	 * @param {*} format формат даты в виде 'DD.MM.YYYY HH:mm:ss'
	 * @param {*} timezone зона времени типа Asia/Almaty
	 * @returns 
	 */
	formatUnixDateUtc(timestamp, format = 'DD.MM.YYYY HH:mm:ss', timezone = 'Asia/Almaty'){
		return moment.unix(timestamp).tz(timezone).utc().format(format);
	},
	
	/**
	 * Преобразование разницы дат формата 0000-00-00 00:00:00 в длительность нужного формата
	 * 
	 * @param {*} date1 дата в формате '0000-00-00 00'
	 * @param {*} date2 дата в формате '0000-00-00 00'
	 * @param {*} format формат даты в виде 'yy ww dd MM hh:mm:ss:SS' formats: https://www.npmjs.com/package/moment-duration-format
	 * @returns 
	 */
	formatDiffDate(date1, date2, format = 'hh:mm:ss'){
		let val = moment.duration(moment.utc(date2).diff(moment.utc(date1))).format(format);
		if(val.length == 2){
			val = '00:00:'+val;
		} else if(val.length == 5){
			val = '00:'+val;
		}
		return val;
	},
	
	/**
	 * Преобразование разницы дат типа timestamp в длительность нужного формата
	 * 
	 * @param {*} timestamp1 дата типа timestamp
	 * @param {*} timestamp2 дата типа timestamp
	 * @param {*} format формат даты в виде 'yy ww dd MM hh:mm:ss:SS' formats: https://www.npmjs.com/package/moment-duration-format
	 * @returns 
	 */
	formatDiffUnixDate(timestamp1, timestamp2, format = 'hh:mm:ss'){
		let val = moment.duration(moment.unix(timestamp2).utc().diff(moment.unix(timestamp1).utc())).format(format);
		if(val.length == 2){
			val = '00:00:'+val;
		} else if(val.length == 5){
			val = '00:'+val;
		}
		return val;
	},
	
	/**
	 * Преобразование секунд в длительность нужного формата
	 * 
	 * @param {*} timestamp1 дата типа timestamp
	 * @param {*} timestamp2 дата типа timestamp
	 * @param {*} format формат даты в виде 'yy ww dd MM hh:mm:ss:SS' formats: https://www.npmjs.com/package/moment-duration-format
	 * @returns 
	 */
	formatSecondsDate(seconds, format = 'hh:mm:ss'){
		let val = moment.duration(seconds, 'seconds').format(format);
		if(val.length == 2){
			val = '00:00:'+val;
		} else if(val.length == 5){
			val = '00:'+val;
		}
		return val;
	},
	
	/**
	 * Создание массива от start до end
	 * пример: range(4, 10) вернет [4, 5, 6, 7, 8, 9, 10]
	 * 
	 * @param {*} start начальное значение в массиве
	 * @param {*} end конечное значение в массиве
	 * @param {*} length максимальная длина массива
	 * @returns {array} массив от start до end с указанной длиной массива
	 */
	range(start, end, length = end - start + 1){
		return Array.from({length}, (_, i) => start + i);
	}
};

export default lib;
