<template>
	<div class="bg-struct bg-struct-details bg-img bg-img-19"></div>
	<div class="main-cont">
		<div id="project-sec" class="mb-5">&nbsp;</div>
		<section class="project-sec">
			<div class="d-flex justify-content-between mb-3">
				<h4 style="z-index: 1;">Добавление категории портфолио</h4>
				<div class="btn-group ms-1" role="group" v-if="authModel.isAdmin()">
					<router-link :to="{path: '/admin/works-categories'}" class="btn btn-secondary">
						<font-awesome-icon :icon="['fas', 'chevron-left']" />
						<span class="ms-2">Назад</span>
					</router-link>
				</div>
			</div>
			<div class="card border-0 shadow-sm" v-if="!loading">
				<form role="form" @submit.prevent="onAdd()">
					<div class="card-body">
						<div class="mb-3">
							<label for="workcatName" class="form-label">Название <span class="text-danger">*</span></label>
							<input type="text" class="form-control" ref="name" id="workcatName" v-model="name" required autofocus />
						</div>
					</div>
					<div class="card-footer text-center">
						<button type="submit" class="btn btn-primary">Добавить</button>
					</div>
					<div class="card-body" v-if="error">
						<div class="alert alert-warning alert-dismissible mb-0" role="alert">
							{{error}}
							<button type="button" class="btn-close" aria-label="Close" @click="error = ''"></button>
						</div>
					</div>
				</form>
			</div>
		</section>
	</div>
</template>

<style lang="sass">

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

import LoadingSpinner from '@/components/LoadingSpinner';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Добавление категории портфолио | Админ-панель | byLex Portfolio'});
	},
	data: () => ({
		name: '',
		
		loading: false,
		error: '',
	}),
	computed: {},
	methods: {
		async onAdd(){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doAddWorksCategory({
				name: this.name,
			});
			if(ret.success){
				this.$router.push({path: '/admin/works-categories/'+ret.id});
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
	},
	mounted(){},
	components: {
		LoadingSpinner,
	},
}
</script>