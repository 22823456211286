<template>
	<div class="bg-struct bg-struct-details bg-img bg-img-19"></div>
	<div class="main-cont">
		<div id="blog-sec" class="mb-5">&nbsp;</div>
		<section class="blog-sec">
			<div class="d-flex justify-content-between mb-3">
				<h4 style="z-index: 1;">Изменение категории записей блога</h4>
				<div class="btn-group ms-1" role="group" v-if="authModel.isAdmin()">
					<router-link :to="{path: '/admin/blog-categories'}" class="btn btn-secondary">
						<font-awesome-icon :icon="['fas', 'chevron-left']" />
						<span class="ms-2">Назад</span>
					</router-link>
				</div>
			</div>
			<div class="card border-0 shadow-sm text-center" v-if="loading">
				<div class="card-body py-4">
					<loading-spinner color="white" text="ЗАГРУЗКА..." />
				</div>
			</div>
			<div class="card border-0 shadow-sm text-center"  v-if="!loading && !blogcat">
				<div class="card-body py-4">
					<h1>ЗАПИСЬ НЕ НАЙДЕНА</h1>
					<div class="mt-4">
						<router-link :to="{path: '/admin/blog-categories'}" class="btn btn-secondary">
							<font-awesome-icon :icon="['fas', 'chevron-left']" />
							<span class="ms-2">Назад</span>
						</router-link>
					</div>
				</div>
			</div>
			<div class="card border-0 shadow-sm" v-if="!loading && blogcat">
				<form role="form" @submit.prevent="onEdit()">
					<div class="card-body">
						<div class="mb-3">
							<label class="form-label">ID</label>
							<input type="text" class="form-control" :value="blogcat.id" readonly disabled />
						</div>
						<div class="mb-3">
							<label for="blogcatName" class="form-label">Название <span class="text-danger">*</span></label>
							<input type="text" class="form-control" ref="name" id="blogcatName" v-model="blogcat.name" required autofocus />
						</div>
					</div>
					<div class="card-footer">
						<div class="d-grid gap-2 d-md-block text-center">
							<button type="submit" class="btn btn-primary">
								<font-awesome-icon :icon="['far', 'floppy-disk']" />
								<span class="ms-2">Сохранить</span>
							</button>
							<button type="button" class="btn btn-danger ms-md-2" @click="onDelete()">
								<font-awesome-icon :icon="['fas', 'trash-can']" />
								<span class="ms-2">Удалить</span>
							</button>
						</div>
					</div>
					<div class="card-body" v-if="error">
						<div class="alert alert-warning alert-dismissible mb-0" role="alert">
							{{error}}
							<button type="button" class="btn-close" aria-label="Close" @click="error = ''"></button>
						</div>
					</div>
				</form>
			</div>
		</section>
	</div>
</template>

<style lang="sass">

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

import LoadingSpinner from '@/components/LoadingSpinner';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Изменение категории записей блога | Админ-панель | byLex Portfolio'});
	},
	data: () => ({
		blogcat: {},
		
		loading: true,
		error: '',
	}),
	computed: {},
	methods: {
		async loadData(){
			this.error = '';
			let ret = await this.appModel.doGetBlogCategory(this.$route.params.id);
			this.blogcat = ret.item;
		},
		
		async onEdit(){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doEditBlogCategory(this.$route.params.id, {
				name: this.blogcat.name,
			});
			if(!ret.success){
				this.error = ret.message;
			}
			this.loading = false;
		},
		async onDelete(){
			if(confirm('Вы уверены?')){
				this.loading = true;
				let ret = await this.appModel.doDeleteBlogCategory(this.$route.params.id);
				if(ret.success){
					this.$router.push({path: '/admin/blog-categories'});
				} else {
					this.error = ret.message;
				}
				this.loading = false;
			}
		},
	},
	async mounted(){
		this.loading = true;
		await this.loadData();
		this.loading = false;
	},
	components: {
		LoadingSpinner,
	},
}
</script>