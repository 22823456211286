import { createI18n } from 'vue-i18n';
import ru from '@/locales/ru.json';
import en from '@/locales/en.json';
//import axios from 'axios';

import AuthModel from '@/models/AuthModel';

export default class AppModel
{
	/**
	 * Конструктор
	 */
	constructor()
	{
		this.hcaptcha_sitekey = 'e9c8a7dc-f9ca-437d-bbc5-ba9fa661b8a6';
		
		this.langs = ['ru', 'en'];
		this.langsName = {
			ru: 'Русский',
			en: 'English',
		};
		this.title = '';
		//this.project_url = 'https://new.bylex.info';
		this.project_url = 'https://bylex.info';
		this.i18n = createI18n({
			legacy: false,
			locale: 'ru',
			messages: { ru, en },
		});
		
		this.auth = new AuthModel();
	}
	
	/**
	 * Set page title
	 */
	setPageTitle(title)
	{
		this.title = title;
		document.title = title;
	}
	
	/**
	 * Set app lang
	 */
	setLang(lang = 'ru')
	{
		this.i18n.global.locale = lang;
		//axios.defaults.headers.common['Accept-Language'] = lang;
		document.querySelector('html').setAttribute('lang', lang);
		return lang;
	}
	
	/**
	 * Returns lang
	 */
	getLang()
	{
		//return this.i18n.locale;
		return this.i18n.global.locale;
	}
	
	pageReload()
	{
		document.location.reload();
	}
	
	/**
	 * Получение списка категорий портфолио
	 */
	async doGetWorksCategories(params)
	{
		return await fetch('/api/data/works-categories?' + new URLSearchParams(params), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Добавление категории портфолио
	 */
	async doAddWorksCategory(params)
	{
		return await fetch('/api/data/works-categories', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение данных о категории портфолио по ее id
	 */
	async doGetWorksCategory(works_category_id)
	{
		return await fetch('/api/data/works-categories/' + works_category_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Изменение данных о категории портфолио по ее id
	 */
	async doEditWorksCategory(works_category_id, params)
	{
		return await fetch('/api/data/works-categories/' + works_category_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление категории портфолио по ее id
	 */
	async doDeleteWorksCategory(works_category_id)
	{
		return await fetch('/api/data/works-categories/' + works_category_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение списка записей портфолио
	 */
	async doGetWorks(params)
	{
		return await fetch('/api/data/works?' + new URLSearchParams(params), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Добавление записи портфолио
	 */
	async doAddWork(params)
	{
		return await fetch('/api/data/works', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение данных о записи портфолио по ее id
	 */
	async doGetWork(work_id)
	{
		return await fetch('/api/data/works/' + work_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Изменение данных о записи портфолио по ее id
	 */
	async doEditWork(work_id, params)
	{
		return await fetch('/api/data/works/' + work_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление записи портфолио по ее id
	 */
	async doDeleteWork(work_id)
	{
		return await fetch('/api/data/works/' + work_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение изображений записи портфолио
	 */
	async doGetWorkImages(work_id)
	{
		return await fetch('/api/data/works/' + work_id + '/images', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Смена постера записи портфолио
	 */
	async doChangeWorkPoster(work_id, image_pkid)
	{
		return await fetch('/api/data/works/' + work_id + '/change-poster/' + image_pkid, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление изображения записи портфолио
	 */
	async doDeleteWorkImage(work_id, image_pkid)
	{
		return await fetch('/api/data/works/' + work_id + '/images/' + image_pkid, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение видео записи портфолио
	 */
	async doGetWorkVideos(work_id)
	{
		return await fetch('/api/data/works/' + work_id + '/videos', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление видео записи портфолио
	 */
	async doDeleteWorkVideo(work_id, video_pkid)
	{
		return await fetch('/api/data/works/' + work_id + '/videos/' + video_pkid, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение 3d моделей записи портфолио
	 */
	async doGetWorkModels3d(work_id)
	{
		return await fetch('/api/data/works/' + work_id + '/models3d', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление 3d модели записи портфолио
	 */
	async doDeleteWorkModel3d(work_id, model3d_pkid)
	{
		return await fetch('/api/data/works/' + work_id + '/models3d/' + model3d_pkid, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение списка категорий блога
	 */
	async doGetBlogCategories(params)
	{
		return await fetch('/api/data/blog-categories?' + new URLSearchParams(params), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Добавление категории блога
	 */
	async doAddBlogCategory(params)
	{
		return await fetch('/api/data/blog-categories', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение данных о категории блога по ее id
	 */
	async doGetBlogCategory(blog_category_id)
	{
		return await fetch('/api/data/blog-categories/' + blog_category_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Изменение данных о категории блога по ее id
	 */
	async doEditBlogCategory(blog_category_id, params)
	{
		return await fetch('/api/data/blog-categories/' + blog_category_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление категории блога по ее id
	 */
	async doDeleteBlogCategory(blog_category_id)
	{
		return await fetch('/api/data/blog-categories/' + blog_category_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение списка записей блога
	 */
	async doGetBlog(params)
	{
		return await fetch('/api/data/blog?' + new URLSearchParams(params), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Добавление записи блога
	 */
	async doAddBlogItem(params)
	{
		return await fetch('/api/data/blog', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение данных о записи блога по ее id
	 */
	async doGetBlogItem(blog_item_id)
	{
		return await fetch('/api/data/blog/' + blog_item_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Изменение данных о записи блога по ее id
	 */
	async doEditBlogItem(blog_item_id, params)
	{
		return await fetch('/api/data/blog/' + blog_item_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление записи блога по ее id
	 */
	async doDeleteBlogItem(blog_item_id)
	{
		return await fetch('/api/data/blog/' + blog_item_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение изображений записи блога
	 */
	async doGetBlogItemImages(blog_item_id)
	{
		return await fetch('/api/data/blog/' + blog_item_id + '/images', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Смена постера записи блога
	 */
	async doChangeBlogItemPoster(blog_item_id, image_pkid)
	{
		return await fetch('/api/data/blog/' + blog_item_id + '/change-poster/' + image_pkid, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление изображения записи блога
	 */
	async doDeleteBlogItemImage(blog_item_id, image_pkid)
	{
		return await fetch('/api/data/blog/' + blog_item_id + '/images/' + image_pkid, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение файлов записи блога
	 */
	async doGetBlogItemFiles(blog_item_id)
	{
		return await fetch('/api/data/blog/' + blog_item_id + '/files', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление файла записи блога
	 */
	async doDeleteBlogItemFile(blog_item_id, file_pkid)
	{
		return await fetch('/api/data/blog/' + blog_item_id + '/files/' + file_pkid, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
}
