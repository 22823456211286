<template>
	<div class="bg-struct bg-struct-details bg-img bg-img-19"></div>
	<div class="main-cont">
		<div id="blog-sec" class="mb-5">&nbsp;</div>
		<section class="blog-sec">
			<div class="d-flex justify-content-between mb-3">
				<h4 style="z-index: 1;">Изменение записи блога</h4>
				<div class="btn-group ms-1" role="group" v-if="authModel.isAdmin()">
					<router-link :to="{path: '/blog/'+$route.params.id, query: Object.assign({}, this.$route.query, {
						category: this.$route.query.category,
						page: this.$route.query.page||1,
					})}" class="btn btn-secondary">
						<font-awesome-icon :icon="['fas', 'chevron-left']" />
						<span class="ms-2">Назад</span>
					</router-link>
				</div>
			</div>
			<div class="card border-0 shadow-sm text-center" v-if="loading">
				<div class="card-body py-4">
					<loading-spinner color="white" text="ЗАГРУЗКА..." />
				</div>
			</div>
			<div class="card border-0 shadow-sm text-center"  v-if="!loading && !blogitem">
				<div class="card-body py-4">
					<h1>ЗАПИСЬ НЕ НАЙДЕНА</h1>
					<div class="mt-4">
						<router-link :to="{path: '/blog', query: Object.assign({}, this.$route.query, {
							category: this.$route.query.category,
							page: this.$route.query.page||1,
						})}" class="btn btn-secondary">
							<font-awesome-icon :icon="['fas', 'chevron-left']" />
							<span class="ms-2">Назад</span>
						</router-link>
					</div>
				</div>
			</div>
			<div class="card border-0 shadow-sm" v-if="!loading && blogitem">
				<form role="form" @submit.prevent="onEdit()">
					<div class="card-body">
						<div class="mb-3">
							<label class="form-label">ID</label>
							<input type="text" class="form-control" :value="blogitem.id" readonly disabled />
						</div>
						<div class="mb-3">
							<label for="blogCategoryId" class="form-label">Категория <span class="text-danger">*</span></label>
							<select class="form-select" ref="category_id" id="blogCategoryId" v-model="blogitem.category_id" required>
								<option :value="blogcat.id" v-for="blogcat in blogCategories" :key="blogcat.id">{{blogcat.name}}</option>
							</select>
						</div>
						<div class="mb-3">
							<label for="blogTitle" class="form-label">Заголовок <span class="text-danger">*</span></label>
							<input type="text" class="form-control" ref="title" id="blogTitle" v-model="blogitem.title" required autofocus />
						</div>
						<div class="mb-3">
							<label for="blogContent" class="form-label">Контент <span class="text-danger">*</span></label>
							<tinymce-editor
								ref="content"
								id="blogContent"
								v-model="blogitem.content"
								api-key="no-api-key"
								:init="{
									language_url: '/assets/plugins/tinymce/langs/ru.js',
									language: 'ru',
									plugins: 'accordion advlist anchor autolink autoresize charmap code directionality fullscreen image insertdatetime link lists media nonbreaking pagebreak preview quickbars searchreplace table visualblocks visualchars wordcount',
									toolbar: 'undo redo | styles fontfamily fontsize | bold italic underline strikethrough blockquote checklist bullist numlist backcolor forecolor | link image media hr codeinline | alignleft aligncenter alignright alignjustify | code removeformat',
									mobile: {
										menubar: true,
										//toolbar_mode: 'scrolling',
									},
									setup: (editor) => {
										editor.ui.registry.addButton('codeinline', {
											icon: 'sourcecode',
											tooltip: 'Форматировать как Код',
											onAction: () => editor.execCommand('mceToggleFormat', false, 'code'),
										});
									},
									promotion: false,
								}"
							/>
						</div>
						<div class="mb-3">
							<label for="blogDateadd" class="form-label">Дата и время <span class="text-danger">*</span></label>
							<input type="datetime-local" class="form-control" ref="dateadd" id="blogDateadd" v-model="blogitem.dateadd" required />
						</div>
					</div>
					<div class="card-body" v-if="blogitem.images.length > 0">
						<label>Изображения</label>
						<div class="images-gallery">
							<lightgallery
								:settings="lightgallerySettings"
								:onInit="onLightGalleryInit"
							>
								<div class="property-image imgbl" v-for="(image, index) in blogitem.images" :key="image.id">
									<a :href="image.url" :data-sub-html="image.descr" class="image block">
										<img class="img-responsive" :src="image.url_small" :style="{width: (!$device.isMobile ? '150px' : '225px'), height: (!$device.isMobile ? '150px' : '225px')}" />
									</a>
									<div class="d-flex gap-2 justify-content-center">
										<button type="button" class="btn btn-secondary" @click="onGetUrl(image.url)" tooltip="Получить ссылку" :disabled="loadingImg">
											<font-awesome-icon :icon="['fas', 'link']" />
										</button>
										<button type="button" class="btn btn-primary" @click="onImageSetPoster(image.pkid)" tooltip="Сделать постером" :disabled="loadingImg" v-if="!image.is_poster">
											<font-awesome-icon :icon="['fas', 'image']" />
										</button>
										<button type="button" class="btn btn-danger" @click="onImageDelete(image.pkid)" tooltip="Удалить изображение" :disabled="loadingImg">
											<font-awesome-icon :icon="['fas', 'trash-can']" />
										</button>
									</div>
								</div>
							</lightgallery>
						</div>
					</div>
					<div class="card-body" v-if="blogitem.files.length > 0">
						<label>Файлы</label>
						<div class="row row-gap-3 d-flex justify-content-center">
							<div class="col-6 col-md-2" v-for="file in blogitem.files" :key="file.id">
								<div class="card">
									<div class="card-body text-center">
										<div class="image" @click="onShowFilePreviewModal(file.pkid)" style="cursor: pointer;" v-if="contains(file.extension, ['jpg', 'jpeg', 'gif', 'bmp', 'png', 'svg', 'webp'], true)">
											<img alt="image" style="height: 96px;" :src="file.url" />
										</div>
										<div class="icon" @click="onShowFilePreviewModal(file.pkid)" style="cursor: pointer;" v-else-if="contains(file.extension, ['mp3', 'amr', 'wav', 'ogg', 'm4a'], true)">
											<font-awesome-icon :icon="['fas', 'music']" size="6x" />
										</div>
										<div class="icon" @click="onShowFilePreviewModal(file.pkid)" style="cursor: pointer;" v-else-if="contains(file.extension, ['mp4', 'avi', 'wmv', 'mov', 'mkv', 'mpg', 'mpeg', 'mpeg4', '3gp', 'webm', 'ogv', 'm4v'], true)">
											<font-awesome-icon :icon="['fas', 'film']" size="6x" />
										</div>
										<div class="icon" @click="onShowFilePreviewModal(file.pkid)" style="cursor: pointer;" v-else>
											<font-awesome-icon :icon="['fas', 'file']" size="6x" />
										</div>
										<div class="file-name">
											<div class="file-extension">{{file.extension}}</div>
										</div>
										<div class="d-flex gap-2 justify-content-center mt-2">
											<button type="button" class="btn btn-secondary" @click="onGetUrl(file.url)" tooltip="Получить ссылку" :disabled="loadingFile">
												<font-awesome-icon :icon="['fas', 'link']" />
											</button>
											<button type="button" class="btn btn-danger" @click="onFileDelete(file.pkid)" tooltip="Удалить файл" :disabled="loadingFile">
												<font-awesome-icon :icon="['fas', 'trash-can']" />
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="card-body">
						<div class="row row-gap-3">
							<div class="col-md-6">
								<div class="bigUploadContainer">
									<h5 class="mt-0">Загрузка изображений</h5>
									<big-upload
										inputFileAccept="image/jpeg,image/png,image/gif,image/svg+xml,image/webp,capture=camera"
										:scriptPath="'/api/data/blog/'+$route.params.id+'/images/upload-chunk'"
										:onSuccess="onBiguploadImgSuccess"
									></big-upload>
								</div>
							</div>
							<div class="col-md-6">
								<div class="bigUploadContainer">
									<h5 class="mt-0">Загрузка файлов</h5>
									<big-upload
										inputFileAccept="image/*,audio/*,video/*,.doc,.docx,.ppt,.pptx,.xls,.xlsx,.txt,.pdf,.rtf,.csv,.svg,capture=camera,capture=camcorder,capture=microphone,capture=filesystem"
										:scriptPath="'/api/data/blog/'+$route.params.id+'/files/upload-chunk'"
										:onSuccess="onBiguploadFileSuccess"
									></big-upload>
								</div>
							</div>
						</div>
					</div>
					<div class="card-footer">
						<div class="d-grid gap-2 d-md-block text-center">
							<button type="submit" class="btn btn-primary">
								<font-awesome-icon :icon="['far', 'floppy-disk']" />
								<span class="ms-2">Сохранить</span>
							</button>
							<button type="button" class="btn btn-danger ms-md-2" @click="onDelete()">
								<font-awesome-icon :icon="['fas', 'trash-can']" />
								<span class="ms-2">Удалить</span>
							</button>
						</div>
					</div>
					<div class="card-body" v-if="error">
						<div class="alert alert-warning alert-dismissible mb-0" role="alert">
							{{error}}
							<button type="button" class="btn-close" aria-label="Close" @click="error = ''"></button>
						</div>
					</div>
				</form>
			</div>
		</section>
	</div>
</template>

<style lang="sass" scoped>
@import 'lightgallery/css/lightgallery.css';
@import 'lightgallery/css/lg-rotate.css';
@import 'lightgallery/css/lg-pager.css';
@import 'lightgallery/css/lg-thumbnail.css';
@import '@/assets/css/lg-thumbnail.css';
@import 'lightgallery/css/lg-transitions.css';
@import 'lightgallery/css/lg-video.css';
@import 'lightgallery/css/lg-zoom.css';

.bigUploadContainer {
	max-width: 350px;
	padding: 20px;
	margin: 0 auto;
	border: 1px solid #555;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	-moz-box-shadow: 0 1px 2px rgba(0,0,0,.05);
	-webkit-box-shadow: 0 1px 2px rgba(0,0,0,.05);
	box-shadow: 0 1px 2px rgba(0,0,0,.05);
	text-align: center;
}

.images-gallery {
	margin: 0;
	padding: 0;
	text-align: center;
}
.images-gallery .property-image {
	display: inline-block;
	margin: 10px 10px;
}
.images-gallery .property-image a.image,
.images-gallery .property-image a.btn {
	display: block;
}
.images-gallery .property-image a.image {
	padding-bottom: 5px;
}
</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

import LoadingSpinner from '@/components/LoadingSpinner';
import BigUpload from '@/components/BigUpload';

// TinyMCE
import 'tinymce/tinymce';
import 'tinymce/icons/default/icons';
import 'tinymce/themes/silver/theme';
import 'tinymce/models/dom/model';
import 'tinymce/skins/ui/oxide-dark/skin.css';

// TinyMCE plugins
// https://www.tiny.cloud/docs/tinymce/6/plugins/
import 'tinymce/plugins/accordion/plugin';
import 'tinymce/plugins/advlist/plugin';
import 'tinymce/plugins/anchor/plugin';
import 'tinymce/plugins/autolink/plugin';
import 'tinymce/plugins/autoresize/plugin';
import 'tinymce/plugins/charmap/plugin';
import 'tinymce/plugins/code/plugin';
import 'tinymce/plugins/directionality/plugin';
import 'tinymce/plugins/fullscreen/plugin';
import 'tinymce/plugins/image/plugin';
import 'tinymce/plugins/insertdatetime/plugin';
import 'tinymce/plugins/link/plugin';
import 'tinymce/plugins/lists/plugin';
import 'tinymce/plugins/media/plugin';
import 'tinymce/plugins/nonbreaking/plugin';
import 'tinymce/plugins/pagebreak/plugin';
import 'tinymce/plugins/preview/plugin';
import 'tinymce/plugins/quickbars/plugin';
import 'tinymce/plugins/searchreplace/plugin';
import 'tinymce/plugins/table/plugin';
import 'tinymce/plugins/visualblocks/plugin';
import 'tinymce/plugins/visualchars/plugin';
import 'tinymce/plugins/wordcount/plugin';

import TinymceEditor from '@tinymce/tinymce-vue';

import Lightgallery from 'lightgallery/vue';
//import lgHash from 'lightgallery/plugins/hash';
import lgZoom from 'lightgallery/plugins/zoom';
import lgRotate from 'lightgallery/plugins/rotate';
import lgVideo from 'lightgallery/plugins/video';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
//import lgPager from 'lightgallery/plugins/pager';

import FilePreviewModal from '@/components/FilePreviewModal';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Изменение записи блога | Админ-панель | byLex Portfolio'});
	},
	data: () => ({
		blogCategories: [],
		
		blogitem: {},
		
		lightgallerySettings: {
			speed: 500,
			plugins: [lgRotate, lgZoom, lgVideo, lgThumbnail],
			actualSize: false,
			showZoomInOutIcons: true,
			toggleThumb: true,
			allowMediaOverlap: true,
			selector: '.image',
		},
		lightGallery: null,
		
		loadingImg: false,
		loadingFile: false,
		loading: true,
		error: '',
    }),
	watch: {
		'blogitem.images': {
			handler(val, oldVal){
				this.$nextTick(() => {
					if(this.lightGallery) this.lightGallery.refresh();
				});
			},
			deep: true,
		},
	},
	computed: {},
	methods: {
		contains: lib.contains,
		formatDate: lib.formatDate,
		formatDateUtc: lib.formatDateUtc,
		
		// инициализация галереи
		onLightGalleryInit(detail){
            this.lightGallery = detail.instance;
        },
		
		async loadBlogCategories(){
			this.error = '';
			let ret = await this.appModel.doGetBlogCategories();
			if(ret.success){
				this.blogCategories = ret.list;
			} else {
				this.error = ret.message;
			}
		},
		async loadImages(){
			this.error = '';
			let ret = await this.appModel.doGetBlogItemImages(this.$route.params.id);
			if(ret.success){
				this.blogitem.images = ret.images;
			} else {
				this.error = ret.message;
			}
		},
		async loadFiles(){
			this.error = '';
			let ret = await this.appModel.doGetBlogItemFiles(this.$route.params.id);
			if(ret.success){
				this.blogitem.files = ret.files;
			} else {
				this.error = ret.message;
			}
		},
		async loadData(){
			this.error = '';
			let ret = await this.appModel.doGetBlogItem(this.$route.params.id);
			this.blogitem = ret.item;
			if(this.blogitem){
				await this.loadImages();
				await this.loadFiles();
			}
		},
		
		// показ окна предпросмотра файла
		onShowFilePreviewModal(file_pkid){
			let that = this;
			this.$vfm.show({
				component: FilePreviewModal,
				on: {
					cancel(close){
						close();
					},
				},
				params: {
					file: that.blogitem.files.find((el) => el.pkid == file_pkid),
				},
			});
		},
		
		onGetUrl(image_url){
			prompt('Ссылка на файл:', image_url);
		},
		async onImageSetPoster(image_pkid){
			if(confirm('Вы уверены?')){
				this.loadingImg = true;
				this.error = '';
				let ret = await this.appModel.doChangeBlogItemPoster(this.$route.params.id, image_pkid);
				if(ret.success){
					await this.loadImages();
				} else {
					this.error = ret.message;
				}
				this.loadingImg = false;
			}
		},
		async onImageDelete(image_pkid){
			if(confirm('Вы уверены?')){
				this.loadingImg = true;
				this.error = '';
				let ret = await this.appModel.doDeleteBlogItemImage(this.$route.params.id, image_pkid);
				if(ret.success){
					await this.loadImages();
				} else {
					this.error = ret.message;
				}
				this.loadingImg = false;
			}
		},
		async onFileDelete(file_pkid){
			if(confirm('Вы уверены?')){
				this.loadingFile = true;
				this.error = '';
				let ret = await this.appModel.doDeleteBlogItemFile(this.$route.params.id, file_pkid);
				if(ret.success){
					await this.loadFiles();
				} else {
					this.error = ret.message;
				}
				this.loadingFile = false;
			}
		},
		
		async onEdit(){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doEditBlogItem(this.$route.params.id, {
				category_id: this.blogitem.category_id,
				title: this.blogitem.title,
				content: this.blogitem.content,
				dateadd: this.formatDateUtc(this.blogitem.dateadd, 'YYYY-MM-DDTHH:mm'),
			});
			if(!ret.success){
				this.error = ret.message;
			}
			this.loading = false;
		},
		async onDelete(){
			if(confirm('Вы уверены?')){
				this.loading = true;
				let ret = await this.appModel.doDeleteBlogItem(this.$route.params.id);
				if(ret.success){
					this.$router.push({path: '/blog', query: Object.assign({}, this.$route.query, {
						category: this.$route.query.category,
						page: this.$route.query.page||1,
					})});
				} else {
					this.error = ret.message;
				}
				this.loading = false;
			}
		},
		
		// успешная загрузка изображения
		async onBiguploadImgSuccess(response){
			if(response.success || false){
				this.loadImages();
			}
		},
		// успешная загрузка файла
		async onBiguploadFileSuccess(response){
			if(response.success || false){
				this.loadFiles();
			}
		},
	},
	async mounted(){
		this.loading = true;
		await this.loadBlogCategories();
		await this.loadData();
		if(this.blogitem){
			this.blogitem.dateadd = this.formatDate(this.blogitem.dateadd, 'YYYY-MM-DDTHH:mm');
		}
		this.loading = false;
	},
	components: {
		LoadingSpinner,
		BigUpload,
		Lightgallery,
		TinymceEditor,
	},
}
</script>