<template>
	<div class="bg-struct bg-struct-details bg-img bg-img-19"></div>
	<div class="main-cont">
		<div id="portfolio_sec" class="mb-5">&nbsp;</div>
		<section class="portfolio-sec">
			<div class="d-flex justify-content-between mb-3">
				<h4 style="z-index: 1;">Категории портфолио</h4>
				<div class="btn-group" role="group" v-if="authModel.isAdmin()">
					<router-link :to="{path: '/admin/works-categories/add'}" class="btn btn-primary">
						<font-awesome-icon :icon="['fas', 'plus']" />
					</router-link>
				</div>
			</div>
			<div class="card border-0 shadow-sm text-center" v-if="loading">
				<div class="card-body py-4">
					<loading-spinner color="white" text="ЗАГРУЗКА..." />
				</div>
			</div>
			<div class="card border-0 shadow-sm text-center"  v-if="!loading && !worksCategories">
				<div class="card-body py-4">
					<h1>КАТЕГОРИЙ ПОРТФОЛИО ПОКА НЕТ</h1>
				</div>
			</div>
			<div v-if="!loading && worksCategories">
				<div class="card bg-dark border-0 shadow-sm">
					<div class="card-body">
						<div class="row row-cols-1 g-3">
							<div class="col" v-for="worksCategory in worksCategories" :key="worksCategory.id">
								<router-link :to="{path: '/admin/works-categories/'+worksCategory.id, query: Object.assign({}, this.$route.query, {
									page: this.page,
								})}" class="card text-decoration-none h-100">
									<div class="card-body">
										<h5 class="card-title mb-0">{{worksCategory.name}}</h5>
									</div>
								</router-link>
							</div>
						</div>
					</div>
				</div>
				<div class="text-center mt-4">
					<div v-if="$device.isMobile">
						<span @click="gotoPage(1)" class="btn btn-white pull-left m-r-xs" :class="{'disabled': page == 1}">
							<font-awesome-icon :icon="['fas', 'angles-left']" />
						</span>
						<span @click="gotoPage(page - 1)" class="btn btn-white pull-left" :class="{'disabled': page == 1}">
							<font-awesome-icon :icon="['fas', 'chevron-left']" />
						</span>
						<span @click="gotoPage(getPagesCount())" class="btn btn-white pull-right m-l-xs" :class="{'disabled': page == getPagesCount()}">
							<font-awesome-icon :icon="['fas', 'angles-right']" />
						</span>
						<span @click="gotoPage(page + 1)" class="btn btn-white pull-right" :class="{'disabled': page == getPagesCount()}">
							<font-awesome-icon :icon="['fas', 'chevron-right']" />
						</span>
						<span class="btn btn-white">{{page}} из {{getPagesCount()}}</span>
					</div>
					<div class="btn-group" v-else>
						<span @click="gotoPage(page - 1)" class="btn btn-white" :class="{'disabled': page == 1}">
							<font-awesome-icon :icon="['fas', 'chevron-left']" />
						</span>
						<span @click="gotoPage(1)" class="btn btn-white" :class="{'active': page == 1}">1</span>
						<span class="btn btn-white disabled" v-if="page - paginationAlpha > 2">...</span>
						<span @click="gotoPage(i)" class="btn btn-white" :class="{'active': page == i}" v-for="i in range(paginationA1, paginationA2)" :key="i" v-if="paginationA2 >= paginationA1">{{i}}</span>
						<span class="btn btn-white disabled" v-if="page + paginationAlpha < getPagesCount() - 1">...</span>
						<span @click="gotoPage(getPagesCount())" class="btn btn-white" :class="{'active': page == getPagesCount()}" v-if="getPagesCount() > 1">{{getPagesCount()}}</span>
						<span @click="gotoPage(page + 1)" class="btn btn-white" :class="{'disabled': page == getPagesCount()}">
							<font-awesome-icon :icon="['fas', 'chevron-right']" />
						</span>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<style lang="sass">

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

import LoadingSpinner from '@/components/LoadingSpinner';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Категории портфолио | Админ-панель | byLex Portfolio'});
	},
	data: () => ({
		worksCategories: [],
		worksCategoriesCount: 0,
		
		page: 1,
		limit: 9,
		loading: true,
		error: '',
		
		paginationAlpha: 3,
	}),
	computed: {
		paginationA1(){
			return Math.max(this.page - this.paginationAlpha, 2);
		},
		paginationA2(){
			return Math.min(this.page + this.paginationAlpha, this.getPagesCount() - 1);
		},
	},
	methods: {
		range: lib.range,
		
		async loadData(){
			this.loading = true;
			this.error = '';
			this.$router.push({query: Object.assign({}, this.$route.query, {
				page: this.page,
			})});
			let params = {
				start: (this.page - 1) * this.limit,
				limit: this.limit,
			};
			let ret = await this.appModel.doGetWorksCategories(params);
			if(ret.success){
				this.worksCategories = ret.list;
				this.worksCategoriesCount = ret.list_count;
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		
		gotoPage(page){
			if(page >= 1 && page <= this.getPagesCount() && page != this.page){
				this.page = page;
				this.loadData();
				window.scrollTo(0, 0);
			}
		},
		// получение количества страниц
		getPagesCount(){
			return Math.ceil(this.worksCategoriesCount / this.limit);
		},
		
		number_format(value){
			return lib.number_format(value, 0, '.', ' ');
		},
	},
	async mounted(){
		if(this.$route.query.page) this.page = parseInt(this.$route.query.page || 1);
		
		this.loading = true;
		await this.loadData();
		this.loading = false;
	},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	components: {
		LoadingSpinner,
	},
}
</script>