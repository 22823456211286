<template>
	<div class="bg-struct bg-struct-details bg-img bg-img-18"></div>
	<div class="main-cont">
		<section class="page-not-found-sec">
			<div class="row">
				<div class="offset-md-3 col-md-6">
					<div class="card border-0 shadow-sm text-center">
						<div class="card-body">
							<h4 class="text-uppercase mt-4">{{$t('header-404')}}</h4>
							<p class="text-muted">{{$t('text-404')}}</p>
							<div class="mt-4">
								<router-link :to="{path: '/'}" class="btn btn-primary waves-effect waves-light">{{$t('btn-goto-main')}}</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<style lang="sass">

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Страница не найдена | byLex Portfolio'});
	},
	data: () => ({
		
    }),
	computed: {},
    methods: {},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	components: {},
};
</script>