<template>
	<div class="bg-struct bg-img bg-img-18"></div>
	<div class="main-cont">
		<div id="about_sec" class="mb-5">&nbsp;</div>
		<section class="about-sec">
			<div class="card border-0 shadow-sm">
				<div class="card-body p-5">
					<div class="row">
						<div class="col-lg-5 text-center">
							<img src="@/assets/img/avatar3.png" class="rounded-circle" style="width: 70%;" />
							<div class="mt-2">
								<a :href="sl.link" target="_blank" class="btn btn-lg btn-link text-body-secondary text-decoration-none p-1" data-bs-toggle="tooltip" data-bs-placement="bottom" :title="sl.title" v-for="sl in appModel.socialLinks">
									<font-awesome-icon :icon="['fab', sl.icon]" />
								</a>
							</div>
						</div>
						<div class="col-lg-7">
							<div class="info-wrap text-center text-lg-start">
								<h1>Алексей Михалёв</h1>
								<h5 class="text-body-secondary mt-3">PHP FULLSTACK РАЗРАБОТЧИК</h5>
								<div class="mt-5">
									<a class="btn btn-success me-2 mb-2" href="/files/mikhalev_alexey_portfolio.pdf" download>
										<font-awesome-icon :icon="['fas', 'download']" class="me-1" /> СКАЧАТЬ РЕЗЮМЕ
									</a>
									<a class="btn btn-primary me-2 mb-2" href="mailto:lexin.ucoz.ru@gmail.com">
										<font-awesome-icon :icon="['far', 'envelope']" class="me-1" /> НАПИСАТЬ
									</a>
									<a class="btn btn-dark me-2 mb-2" href="//t.me/lexinzector" target="_blank" style="padding: 3px; min-width: 35px;">
										<img src="@/assets/img/telegram-icon.png" style="width: 35px; height: 35px;" />
									</a>
									<a class="btn btn-dark me-2 mb-2" href="//wa.me/77016838289" target="_blank" style="padding: 3px; min-width: 35px;">
										<img src="@/assets/img/whatsapp-icon.png" style="width: 35px; height: 35px;" />
									</a>
								</div>
							</div>
							<div class="profile-wrap mt-4">
								<div class="row">
									<div class="col-md-4">Возраст</div>
									<div class="col-md-8 text-body-secondary">{{dateDiff('1993-11-30', Date.now()).years}}</div>
								</div>
								<div class="row">
									<div class="col-md-4">Email</div>
									<div class="col-md-8 text-body-secondary">
										<a href="mailto:lexin.ucoz.ru@gmail.com" class="text-decoration-none">lexin.ucoz.ru@gmail.com</a>
									</div>
								</div>
								<div class="row">
									<div class="col-md-4">Телефоны</div>
									<div class="col-md-8 text-body-secondary">
										<a href="tel:+77016838289" class="text-decoration-none">+7 701 68 38 28 9</a>, 
										<a href="tel:+77712563235" class="text-decoration-none">+7 771 256 32 35</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="mt-4 text-center text-body-secondary">Программирую с 8-ми лет (начал с web-технологий и продолжил windows-технологиями, познаю linux-технологии). До устройства на первую работу занимался разработкой дипломных работ, сайтов и программ.</div>
				</div>
			</div>
		</section>
		
		<div id="interest_sec" class="mb-5">&nbsp;</div>
		<section class="interest-sec">
			<h4 class="mb-3">Интересы</h4>
			<div class="row row-gap-3">
				<div class="col-6 col-md-2" v-for="interest in interests">
					<div class="card border-0 shadow-sm text-center">
						<div class="card-body">
							<font-awesome-icon :icon="['fas', interest.icon]" size="3x" class="text-warning mb-2" />
							<div class="text-body-secondary">{{interest.text}}</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		
		<div id="skills_sec" class="mb-5">&nbsp;</div>
		<section class="skills-sec">
			<div class="row">
				<div class="col-sm-12">
					<h4 class="mb-3">Профессиональные навыки</h4>
					<div class="text-center">
						<span class="skill-tag" v-for="skill in skills.pro">{{skill}}</span>
					</div>
				</div>
			</div>
			<div class="row mt-5">
				<div class="col-sm-12">
					<h4 class="mb-3">Изучаю</h4>
					<div class="text-center">
						<span class="skill-tag" v-for="skill in skills.learn">{{skill}}</span>
					</div>
				</div>
			</div>
			<div class="row mt-5">
				<div class="col-sm-12">
					<h4 class="mb-3">Знание языков</h4>
					<div class="text-center">
						<span class="skill-tag" v-for="skill in skills.langs">{{skill}}</span>
					</div>
				</div>
			</div>
			<div class="row mt-5">
				<div class="col-sm-12">
					<h4 class="mb-3">Личные качества</h4>
					<div class="text-center">
						<span class="skill-tag" v-for="skill in skills.qualities">{{skill}}</span>
					</div>
				</div>
			</div>
		</section>
		
		<div id="portfolio_sec" class="mb-5">&nbsp;</div>
		<section class="portfolio-sec">
			<div class="d-flex justify-content-between mb-3">
				<h4>Портфолио</h4>
				<div class="d-none d-lg-block">
					<div class="btn-group" role="group">
						<router-link :to="{path: '/works'}" class="btn btn-dark active">ВСЕ</router-link>
						<router-link :to="{path: '/works', query: Object.assign({}, this.$route.query, {
							category: workcat.id,
						})}" class="btn btn-dark" v-for="workcat in worksCategories" :key="workcat.id">{{workcat.name}}</router-link>
					</div>
				</div>
				<div class="d-block d-lg-none">
					<div class="dropdown">
						<button class="btn btn-dark px-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
							<font-awesome-icon :icon="['fas', 'list']" />
						</button>
						<ul class="dropdown-menu dropdown-menu-end">
							<li>
								<router-link :to="{path: '/works'}" class="dropdown-item py-2 active">Все работы</router-link>
							</li>
							<li v-for="workcat in worksCategories">
								<router-link :to="{path: '/works/cat'+workcat.id}" class="dropdown-item py-2" :key="workcat.id">{{workcat.name}}</router-link>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="gallery-wrap">
				<div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
					<div class="col" v-for="work in works" :key="work.id">
						<router-link :to="{path: '/works/'+work.id}" class="card border-0 shadow-sm text-decoration-none h-100">
							<div class="card-img-cont">
								<div class="card-img-out">
									<div class="card-img-in">
										<img :src="getPoster(work.images) ? getPoster(work.images).url_small : '/assets/img/no-image.svg'" />
									</div>
								</div>
							</div>
							<div class="card-body">
								<h5 class="card-title">{{work.title}}</h5>
							</div>
						</router-link>
					</div>
				</div>
			</div>
			<div class="text-center mt-4 mb-3">
				<router-link :to="{path: '/works'}" class="btn btn-dark">Посмотреть все работы</router-link>
			</div>
		</section>
		
		<div id="experience_sec" class="mb-5">&nbsp;</div>
		<section class="experience-sec">
			<h4 class="mb-3">Опыт работы (более 11 лет)</h4>
			<div class="timeline-wrap clearfix">
				<ul class="timeline p-0">
					<li>
						<div class="timeline-badge">
							<img src="@/assets/img/bmc_logo.svg" class="p-1" />
						</div>
						<div class="timeline-panel card border-3 border-start-0 border-end-0 border-bottom-0 border-danger shadow-sm p-3">
							<div class="timeline-heading">
								<h5>FullStack разработчик</h5>
								<div class="duration text-body-tertiary mb-1">Февраль 2024 — настоящее время</div>
								<div class="institution">ТОО "BenchMark Consulting" (<a href="https://bmconsult.kz" target="_blank">bmconsult.kz</a>, <a href="https://kdt.kz" target="_blank">kdt.kz</a>)</div>
							</div>
							<div class="timeline-body text-body-tertiary">
								<p class="mt-3 mb-0">Разработка и сопровождение web-проектов, запуск новых проектов, Разработка мобильного приложения, Верстка, Доработка и модернизация внутреннего php-фреймворка, Работа с системами контроля версий (mercurial), Работа с системами багтрекинга (redmine, trac), Тестирование разработанных проектов, Администрирование разработанных web-проектов, Администрирование серверов (linux), Проектирование баз данных, Проектирование кластерной системы, Проектирование high-load проектов, Обучение стажеров внутреннему php-фреймворку (нашей разработки), Работа с клиентами</p>
							</div>
						</div>
					</li>
					<li class="timeline-inverted">
						<div class="timeline-badge">
							<img src="@/assets/img/kmbio_logo.svg" class="p-1" />
						</div>
						<div class="timeline-panel card border-3 border-start-0 border-end-0 border-bottom-0 border-warning shadow-sm p-3">
							<div class="timeline-heading">
								<h5>Мастер производственного обучения по специальности "Вычислительная Техника и Программное Обеспечение"</h5>
								<div class="duration text-body-tertiary mb-1">Сентябрь 2022 - Июль 2023 (10 мес.)</div>
								<div class="institution">ТОО "Колледж Менеджмента, Бизнеса и Образования" г. Астана (<a href="https://kmib.edu.kz" target="_blank">kmib.edu.kz</a>)</div>
							</div>
							<div class="timeline-body text-body-tertiary">
								<p class="mt-3 mb-0">Преподавание специальных дисциплин по специальности "Вычислительная Техника и Программное Обеспечение" (теория, практика)</p>
							</div>
						</div>
					</li>
					<li>
						<div class="timeline-badge">
							<img src="@/assets/img/bmc_logo.svg" class="p-1" />
						</div>
						<div class="timeline-panel card border-3 border-start-0 border-end-0 border-bottom-0 border-primary shadow-sm p-3">
							<div class="timeline-heading">
								<h5>Программист-разработчик</h5>
								<div class="duration text-body-tertiary mb-1">Февраль 2022 — Сентябрь 2023 (1 год 8 мес.)</div>
								<div class="institution">ТОО "BenchMark Consulting" (<a href="http://bmconsult.kz" target="_blank">bmconsult.kz</a>, <a href="http://kdt.kz" target="_blank">kdt.kz</a>)</div>
							</div>
							<div class="timeline-body text-body-tertiary">
								<p class="mt-3 mb-0">Разработка и сопровождение web-проектов, запуск новых проектов, Разработка мобильного приложения, Верстка, Доработка и модернизация внутреннего php-фреймворка, Работа с системами контроля версий (mercurial), Работа с системами багтрекинга (redmine, trac), Тестирование разработанных проектов, Администрирование разработанных web-проектов, Администрирование серверов (linux), Проектирование баз данных, Проектирование кластерной системы, Проектирование high-load проектов, Обучение стажеров внутреннему php-фреймворку (нашей разработки), Работа с клиентами</p>
							</div>
						</div>
					</li>
					<li class="timeline-inverted">
						<div class="timeline-badge">
							<img src="@/assets/img/kmbio_logo.svg" class="p-1" />
						</div>
						<div class="timeline-panel card border-3 border-start-0 border-end-0 border-bottom-0 border-success shadow-sm p-3">
							<div class="timeline-heading">
								<h5>Мастер производственного обучения по специальности "Вычислительная Техника и Программное Обеспечение"</h5>
								<div class="duration text-body-tertiary mb-1">Январь 2021 - Сентябрь 2021 (9 мес.)</div>
								<div class="institution">ТОО "Колледж Менеджмента, Бизнеса и Образования" г. Астана (<a href="https://kmib.edu.kz" target="_blank">kmib.edu.kz</a>)</div>
							</div>
							<div class="timeline-body text-body-tertiary">
								<p class="mt-3 mb-0">Преподавание специальных дисциплин по специальности "Вычислительная Техника и Программное Обеспечение" (теория, практика)</p>
							</div>
						</div>
					</li>
					<li>
						<div class="timeline-badge">
							<img src="@/assets/img/bmc_logo.svg" class="p-1" />
						</div>
						<div class="timeline-panel card border-3 border-start-0 border-end-0 border-bottom-0 border-danger shadow-sm p-3">
							<div class="timeline-heading">
								<h5>FullStack разработчик</h5>
								<div class="duration text-body-tertiary mb-1">Январь 2021 — Декабрь 2021 (12 мес.)</div>
								<div class="institution">ТОО "BenchMark Consulting" (<a href="http://bmconsult.kz" target="_blank">bmconsult.kz</a>, <a href="http://kdt.kz" target="_blank">kdt.kz</a>)</div>
							</div>
							<div class="timeline-body text-body-tertiary">
								<p class="mt-3 mb-0">Разработка и сопровождение web-проектов, запуск новых проектов, Разработка мобильного приложения, Верстка, Доработка и модернизация внутреннего php-фреймворка, Работа с системами контроля версий (mercurial), Работа с системами багтрекинга (redmine, trac), Тестирование разработанных проектов, Администрирование разработанных web-проектов, Администрирование серверов (linux), Проектирование баз данных, Проектирование кластерной системы, Проектирование high-load проектов, Обучение стажеров внутреннему php-фреймворку (нашей разработки), Работа с клиентами</p>
							</div>
						</div>
					</li>
					<li class="timeline-inverted">
						<div class="timeline-badge">
							<img src="@/assets/img/kmib_logo.png" class="p-1" />
						</div>
						<div class="timeline-panel card border-3 border-start-0 border-end-0 border-bottom-0 border-warning shadow-sm p-3">
							<div class="timeline-heading">
								<h5>Мастер производственного обучения по специальности "Вычислительная Техника и Программное Обеспечение"</h5>
								<div class="duration text-body-tertiary mb-1">Сентябрь 2020 - Январь 2021 (5 мес.)</div>
								<div class="institution">ТОО "Колледж Менеджмента и Бизнеса" г. Астана (<a href="https://kmib.edu.kz" target="_blank">kmib.edu.kz</a>)</div>
							</div>
							<div class="timeline-body text-body-tertiary">
								<p class="mt-3 mb-0">Преподавание специальных дисциплин по специальности "Вычислительная Техника и Программное Обеспечение" (теория, практика)</p>
							</div>
						</div>
					</li>
					<li>
						<div class="timeline-badge">
							<img src="@/assets/img/bmc_logo.svg" class="p-1" />
						</div>
						<div class="timeline-panel card border-3 border-start-0 border-end-0 border-bottom-0 border-primary shadow-sm p-3">
							<div class="timeline-heading">
								<h5>FullStack разработчик</h5>
								<div class="duration text-body-tertiary mb-1">Ноябрь 2019 — Декабрь 2020 (1 год 2 мес.)</div>
								<div class="institution">ТОО "BenchMark Consulting" (<a href="http://bmconsult.kz" target="_blank">bmconsult.kz</a>, <a href="http://kdt.kz" target="_blank">kdt.kz</a>)</div>
							</div>
							<div class="timeline-body text-body-tertiary">
								<p class="mt-3 mb-0">Разработка и сопровождение web-проектов, запуск новых проектов, Разработка мобильного приложения, Верстка, Доработка и модернизация внутреннего php-фреймворка, Работа с системами контроля версий (mercurial), Работа с системами багтрекинга (redmine, trac), Тестирование разработанных проектов, Администрирование разработанных web-проектов, Администрирование серверов (linux), Проектирование баз данных, Проектирование кластерной системы, Проектирование high-load проектов, Обучение стажеров внутреннему php-фреймворку (нашей разработки), Работа с клиентами</p>
							</div>
						</div>
					</li>
					<li class="timeline-inverted">
						<div class="timeline-badge">
							<img src="@/assets/img/bmc_logo.svg" class="p-1" />
						</div>
						<div class="timeline-panel card border-3 border-start-0 border-end-0 border-bottom-0 border-success shadow-sm p-3">
							<div class="timeline-heading">
								<h5>Главный разработчик</h5>
								<div class="duration text-body-tertiary mb-1">Декабрь 2017 - Июль 2019 (1 год 8 мес.)</div>
								<div class="institution">ТОО "BenchMark Consulting" (<a href="http://bmconsult.kz" target="_blank">bmconsult.kz</a>, <a href="http://kdt.kz" target="_blank">kdt.kz</a>)</div>
							</div>
							<div class="timeline-body text-body-tertiary">
								<p class="mt-3 mb-0">Разработка и сопровождение web-проектов, запуск новых проектов, Разработка мобильного приложения, Верстка, Доработка и модернизация внутреннего php-фреймворка, Работа с системами контроля версий (mercurial), Работа с системами багтрекинга (redmine, trac), Тестирование разработанных проектов, Администрирование разработанных web-проектов, Администрирование серверов (linux), Проектирование баз данных, Проектирование кластерной системы, Проектирование high-load проектов, Обучение стажеров внутреннему php-фреймворку (нашей разработки), Работа с клиентами</p>
							</div>
						</div>
					</li>
					<li>
						<div class="timeline-badge">
							<img src="@/assets/img/bmc_logo.svg" class="p-1" />
						</div>
						<div class="timeline-panel card border-3 border-start-0 border-end-0 border-bottom-0 border-danger shadow-sm p-3">
							<div class="timeline-heading">
								<h5>Руководитель отдела IT-разработок</h5>
								<div class="duration text-body-tertiary mb-1">Май 2016 — Декабрь 2017 (1 год 8 мес.)</div>
								<div class="institution">ТОО "BenchMark Consulting" (<a href="http://bmconsult.kz" target="_blank">bmconsult.kz</a>, <a href="http://kdt.kz" target="_blank">kdt.kz</a>)</div>
							</div>
							<div class="timeline-body text-body-tertiary">
								<p class="mt-3 mb-0">Разработка и сопровождение web-проектов, запуск новых проектов, Разработка мобильного приложения, Верстка, Доработка и модернизация внутреннего php-фреймворка, Работа с системами контроля версий (mercurial), Работа с системами багтрекинга (redmine, trac), Тестирование разработанных проектов, Администрирование разработанных web-проектов, Администрирование серверов (linux), Проектирование баз данных, Проектирование кластерной системы, Проектирование high-load проектов, Обучение стажеров внутреннему php-фреймворку (нашей разработки), Работа с клиентами</p>
							</div>
						</div>
					</li>
					<li class="timeline-inverted">
						<div class="timeline-badge">
							<img src="@/assets/img/bmc_logo.svg" class="p-1" />
						</div>
						<div class="timeline-panel card border-3 border-start-0 border-end-0 border-bottom-0 border-warning shadow-sm p-3">
							<div class="timeline-heading">
								<h5>Программист</h5>
								<div class="duration text-body-tertiary mb-1">Февраль 2015 - Май 2016 (1 год 4 мес.)</div>
								<div class="institution">ТОО "BenchMark Consulting" (<a href="http://bmconsult.kz" target="_blank">bmconsult.kz</a>, <a href="http://kdt.kz" target="_blank">kdt.kz</a>)</div>
							</div>
							<div class="timeline-body text-body-tertiary">
								<p class="mt-3 mb-0">Разработка и сопровождение web-проектов, запуск новых проектов, Разработка мобильного приложения, Верстка, Доработка и модернизация внутреннего php-фреймворка, Работа с системами контроля версий (mercurial), Работа с системами багтрекинга (redmine, trac), Тестирование разработанных проектов, Администрирование разработанных web-проектов, Администрирование серверов (linux), Проектирование баз данных, Проектирование кластерной системы, Проектирование high-load проектов, Обучение стажеров внутреннему php-фреймворку (нашей разработки), Работа с клиентами</p>
							</div>
						</div>
					</li>
					<li>
						<div class="timeline-badge">
							<img src="@/assets/img/bmc_logo.svg" class="p-1" />
						</div>
						<div class="timeline-panel card border-3 border-start-0 border-end-0 border-bottom-0 border-primary shadow-sm p-3">
							<div class="timeline-heading">
								<h5>Начальник отдела IT-разработок</h5>
								<div class="duration text-body-tertiary mb-1">Июнь 2014 — Ноябрь 2014 (6 мес.)</div>
								<div class="institution">ТОО "BenchMark Consulting" (<a href="http://bmconsult.kz" target="_blank">bmconsult.kz</a>, <a href="http://kdt.kz" target="_blank">kdt.kz</a>)</div>
							</div>
							<div class="timeline-body text-body-tertiary">
								<p class="mt-3 mb-0">Разработка и сопровождение web-проектов, запуск новых проектов, Разработка мобильного приложения, Верстка, Доработка и модернизация внутреннего php-фреймворка, Работа с системами контроля версий (mercurial), Работа с системами багтрекинга (redmine, trac), Тестирование разработанных проектов, Администрирование разработанных web-проектов, Администрирование серверов (linux), Проектирование баз данных, Проектирование кластерной системы, Проектирование high-load проектов, Обучение стажеров внутреннему php-фреймворку (нашей разработки), Работа с клиентами</p>
							</div>
						</div>
					</li>
					<li class="timeline-inverted">
						<div class="timeline-badge">
							<img src="@/assets/img/bmc_logo.svg" class="p-1" />
						</div>
						<div class="timeline-panel card border-3 border-start-0 border-end-0 border-bottom-0 border-success shadow-sm p-3">
							<div class="timeline-heading">
								<h5>Программист</h5>
								<div class="duration text-body-tertiary mb-1">Август 2013 - Июнь 2014 (11 мес.)</div>
								<div class="institution">ТОО "BenchMark Consulting" (<a href="http://bmconsult.kz" target="_blank">bmconsult.kz</a>, <a href="http://kdt.kz" target="_blank">kdt.kz</a>)</div>
							</div>
							<div class="timeline-body text-body-tertiary">
								<p class="mt-3 mb-0">Разработка и сопровождение web-проектов, запуск новых проектов, Разработка мобильного приложения, Верстка, Доработка и модернизация внутреннего php-фреймворка, Работа с системами контроля версий (mercurial), Работа с системами багтрекинга (redmine, trac), Тестирование разработанных проектов, Администрирование разработанных web-проектов, Администрирование серверов (linux), Проектирование баз данных, Проектирование кластерной системы, Проектирование high-load проектов, Обучение стажеров внутреннему php-фреймворку (нашей разработки), Работа с клиентами</p>
							</div>
						</div>
					</li>
					<li class="float-none"></li>
				</ul>
			</div>
		</section>
		
		<div id="education_sec" class="mb-5">&nbsp;</div>
		<section class="education-sec">
			<h4 class="mb-3">Образование</h4>
			<div class="timeline-wrap clearfix">
				<ul class="timeline p-0">
					<li>
						<div class="timeline-badge bg-primary no-icon"></div>
						<div class="timeline-panel card border-3 border-start-0 border-end-0 border-bottom-0 border-primary shadow-sm p-3">
							<div class="timeline-heading">
								<h5>Техник-программист, Оператор ЭВМ</h5>
								<div class="duration text-body-tertiary mb-1">2009 - 2013</div>
								<div class="institution">Колледж Менеджмента и Бизнеса г. Астана</div>
							</div>
						</div>
					</li>
					<li class="timeline-inverted">
						<div class="timeline-badge bg-danger no-icon"></div>
						<div class="timeline-panel card border-3 border-start-0 border-end-0 border-bottom-0 border-danger shadow-sm p-3">
							<div class="timeline-heading">
								<h5>Среднее образование</h5>
								<div class="duration text-body-tertiary mb-1">2000 - 2009</div>
								<div class="institution">Средняя Школа № 18 г. Астана</div>
							</div>
						</div>
					</li>
					<li class="float-none"></li>
				</ul>
			</div>
		</section>
		
		<div id="blog_sec" class="mb-5">&nbsp;</div>
		<section class="blog-sec">
			<div class="d-flex justify-content-between mb-3">
				<h4>Блог</h4>
				<div class="d-none d-lg-block">
					<div class="btn-group" role="group">
						<router-link :to="{path: '/blog'}" class="btn btn-dark active">ВСЕ</router-link>
						<router-link :to="{path: '/blog', query: Object.assign({}, this.$route.query, {
							category: blogcat.id,
						})}" class="btn btn-dark" v-for="blogcat in blogCategories" :key="blogcat.id">{{blogcat.name}}</router-link>
					</div>
				</div>
				<div class="d-block d-lg-none">
					<div class="dropdown">
						<button class="btn btn-dark px-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
							<font-awesome-icon :icon="['fas', 'list']" />
						</button>
						<ul class="dropdown-menu dropdown-menu-end">
							<li>
								<router-link :to="{path: '/blog'}" class="dropdown-item py-2 active">Все записи</router-link>
							</li>
							<li v-for="blogcat in blogCategories" :key="blogcat.id">
								<router-link :to="{path: '/blog/cat'+blogcat.id}" class="dropdown-item py-2">{{blogcat.name}}</router-link>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
				<div class="col" v-for="blogitem in blog" :key="blogitem.id">
					<router-link :to="{path: '/blog/'+blogitem.id}" class="card border-0 shadow-sm text-decoration-none h-100">
						<div class="card-img-cont">
							<div class="card-img-out">
								<div class="card-img-in">
									<img :src="blogitem.poster ? blogitem.poster.url_small : '/assets/img/no-image.svg'" />
								</div>
							</div>
						</div>
						<div class="card-body">
							<div class="card-text text-body-secondary">{{blogitem.category_name}}</div>
							<h5 class="card-title">{{blogitem.title}}</h5>
						</div>
						<div class="card-footer">
							<small class="text-body-secondary">{{formatDate(blogitem.dateadd, 'DD.MM.YYYY')}}</small>
						</div>
					</router-link>
				</div>
			</div>
			<div class="text-center mt-4 mb-3">
				<router-link :to="{path: '/blog'}" class="btn btn-dark">Посмотреть все записи</router-link>
			</div>
		</section>
	</div>
	
	<div class="mt-5 text-center">
		<a href="https://megastock.ru" target="_blank" class="me-1">
			<img src="@/assets/img/acc_blue_on_white_ru.png" alt="www.megastock.ru" border="0" />
		</a>
		<a href="https://passport.webmoney.ru/asp/certview.asp?wmid=214886349299" target="_blank">
			<img src="@/assets/img/wm_v_blue_on_white_ru.png" data-bs-toggle="tooltip" data-bs-placement="top" title="Здесь находится аттестат моего WM идентификатора 214886349299" border="0" />
		</a>
	</div>
</template>

<style lang="sass">

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Главная | byLex Portfolio'});
	},
	data: () => ({
		interests: [
			{
				icon: 'code',
				text: 'Код',
			},
			{
				icon: 'bicycle',
				text: 'Велоспорт',
			},
			{
				icon: 'music',
				text: 'Музыка',
			},
			{
				icon: 'gamepad',
				text: 'Игры',
			},
			{
				icon: 'plane-up',
				text: 'Путешествия',
			},
			{
				icon: 'camera',
				text: 'Фотография',
			},
		],
		skills: {
			pro: [
				'PHP',
				'SQL',
				'HTML',
				'CSS',
				'SCSS',
				'JavaScript',
				'Ajax',
				'jQuery',
				'Bootstrap 3/4/5',
				'XML',
				'JSON',
				'REST',
				'Twig',
				'Composer',
				'NPM',
				
				'Laravel',
				'Lumen',
				'Silex',
				'Vue2/3',
				
				'C#',
				'C++ for Arduino',
				'Visual Basic 5.0/6.0',
				'Visual Basic .Net',
				'.Net Framework',
				
				'Mercurial',
				'Git',
				
				'MySQL',
				'SQLite3',
				'MongoDB',
				
				'Bash/Shell',
				
				'Ubuntu',
				'Raspbian',
				'DietPi',
				'Debian',
				'CentOS 6/7',
				
				'Nginx',
				'Apache',
				'PHP-FPM',
				
				'Adminer',
				'DokuWiki',
				'Redmine',
				'Tmux',
				'Memcached',
				'Minio',
				
				'Adobe Photoshop',
				'Adobe Premiere Pro',
				'Adobe Audition',
				'Adobe After Effects',
				'Android Studio',
				'CorelDraw',
				'Lunacy',
				'Axialis Icon Workshop',
				'Axialis Cursor Workshop',
				'NotePad++',
				'Visual Studio Code',
				'FileZilla',
				'Cygwin',
				'PuTTY',
				'OpenServer',
				'Arduino IDE',
				
				'MS Office',
			],
			learn: [
				//'Angular',
				//'React',
				'Cordova',
				'Electron',
				'Symfony',
				//'CodeIgniter',
				'Node.js',
				'Flatpak',
				'Flutter',
				'Tailwind',
				
				'Java for Android Studio',
				'Python',
				
				'Yii 1/2/3',
				'WordPress',
				
				'PostgreSQL',
				
				'WPF .Net with Blend',
				'TDD (умение тестировать свой код)',
				
				'Zabbix',
				'Docker',
				'Kubernetes',
				'Redis',
				'ElasticSearch',
				'Sphinx',
				'RabbitMQ',
				
				'Adobe Illustrator',
				
				'Cinema4D',
				'ZBrush',
				'Google SketchUp',
				
				'Ableton Live',
				'Melodics',
			],
			langs: [
				'Русский (родной)',
				'Английский (B2: средне-продвинутый)',
				'Казахский (A1: начальный)',
			],
			qualities: [
				'Коммуникабельный',
				'Ответственный',
				'Вежливый',
				'Честный',
				'Пунктуальный',
				'Готов к работе в команде и индивидуально',
				'Выполняю работу в срок',
				'Отсутствие вредных привычек',
			],
		},
		worksCategories: [],
		works: [],
		blogCategories: [],
		blog: [],
	}),
	computed: {},
	methods: {
		formatDate: lib.formatDate,
		dateDiff: lib.dateDiff,
		
		getPoster(images){
			return images.find((el) => el.is_poster);
		},
		getImages(images){
			return images.filter((el) => !el.is_poster);
		},
		getBlogCategory(id){
			return this.blogCategories.find((el) => el.id == id);
		},
		
		async loadWorksCategories(){
			let ret = await this.appModel.doGetWorksCategories();
			if(ret.success){
				this.worksCategories = ret.list;
			}
		},
		async loadWorks(){
			let ret = await this.appModel.doGetWorks({
				limit: 6,
			});
			if(ret.success){
				this.works = ret.list;
			}
		},
		async loadBlogCategories(){
			let ret = await this.appModel.doGetBlogCategories();
			if(ret.success){
				this.blogCategories = ret.list;
			}
		},
		async loadBlog(){
			let ret = await this.appModel.doGetBlog({
				limit: 3,
			});
			if(ret.success){
				this.blog = ret.list;
			}
		},
	},
	async mounted(){
		this.loadWorksCategories();
		this.loadWorks();
		this.loadBlogCategories();
		this.loadBlog();
	},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	components: {},
}
</script>