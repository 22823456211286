<template>
	<div class="bg-struct bg-struct-details bg-img bg-img-18"></div>
	<div class="main-cont">
		<div class="row align-items-center justify-content-center">
			<div class="col-md-8 col-lg-6 col-xl-5">
				<div class="card shadow-none">
					<div class="card-body text-center p-4">
						<div @click="onAuthBylex()" class="btn btn-primary" v-if="!authModel.isAuth()">Войти</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="sass">

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

import { Bylex } from '@/assets/plugins/socials';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Авторизация | byLex Portfolio'});
	},
	data: () => ({
		
	}),
	methods: {
		async onAuthBylex(){
			await this.$Oauth.authenticate('bylex', Bylex).then(async (response) => {
				this.authModel.authChecked = false;
				await this.authModel.doCheckAuth().then(() => {
					this.$router.push('/');
				});
			}).catch((err) => {
				console.log(err);
			});
		},
	},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	mounted(){},
	beforeUnmount(){},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	computed: {},
	components: {},
};
</script>