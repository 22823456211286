<template>
	<input type="file" class="inputFile mb-2" ref="file" :accept="inputFileAccept" />
	<button type="button" class="btn btn-primary me-1" @click="fire">{{btnStartText}}</button>
	<button type="button" class="btn btn-danger" @click="abortFileUpload" v-if="uploadStarted">Отмена</button>
	<div class="progress mt-2 mb-0">
		<div style="color: #000;" :style="{width: progressPercent + '%'}" aria-valuemax="100" aria-valuemin="0" :aria-valuenow="progressPercent" role="progressbar" class="progress-bar" :class="{'progress-bar-default': !error, 'progress-bar-danger': error}">
			{{progressPercent != '0' ? (progressPercent + '%') : ''}}
		</div>
	</div>
	<div class="alert alert-warning mt-2 mb-0" style="padding: 0;" v-if="responseText">{{responseText}}</div>
	<div class="alert alert-info mt-2 mb-0" style="padding: 0;" v-if="timeRemaining">{{timeRemaining}}</div>
</template>

<style lang="sass" scoped>
.inputFile {
	border: 1px solid #555;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	-moz-box-shadow: 0 1px 2px rgba(0,0,0,.05);
	-webkit-box-shadow: 0 1px 2px rgba(0,0,0,.05);
	box-shadow: 0 1px 2px rgba(0,0,0,.05);
	padding: 10px;
	width: 100%;
	max-width: 100%;
}
</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	inheritAttrs: false,
	//emits: ['confirm', 'cancel']
	props: {
		inputFileAccept: {
			type: String,
			default: '',
		},
		//Path to the php script for handling the uploads
		scriptPath: {
			type: String,
			default: 'inc/bigUpload.php',
		},
		//Additional URL variables to be passed to the script path
		//ex: &foo=bar
		scriptPathParams: {
			type: [String, URLSearchParams],
			default: '',
		},
		//Size of chunks to upload (in bytes)
		//Default: 1 MB
		chunkSize: {
			type: Number,
			default: 1000000,
		},
		//Max file size allowed
		//Default: 2 GB
		maxFileSize: {
			type: Number,
			default: 2147483648,
		},
		
		//Fail callback
		onFail: {
			type: Function,
			default: function(response){
				return true;
			},
		},
		onStart: {
			type: Function,
			default: function(response){
				return true;
			},
		},
		//Pause callback
		onPause: {
			type: Function,
			default: function(){
				return true;
			},
		},
		//Resume callback
		onResume: {
			type: Function,
			default: function(){
				return true;
			},
		},
		//Cancel callback
		onCancel: {
			type: Function,
			default: function(){
				return true;
			},
		},
		//Success callback
		onSuccess: {
			type: Function,
			default: function(response){
				return true;
			},
		},
	},
	data: () => ({
		progressPercent: 0,
		responseText: '',
		timeRemaining: '',
		btnStartText: 'Старт загрузки',
		
		uploadStarted: false,
		file: false,
		numberOfChunks: 0,
		
		// Не была ли загрузка отменена пользователем
		aborted: false,
		
		paused: false,
		pauseChunk: 0,
		
		// Временное имя файла
		// Если сервер увидит его как 0, он сгенерирует новое имя файла и передаст его обратно как текст ответа, который обновит key новым именем файла, которое будет использоваться
		// Когда этот метод отправляет допустимое имя файла, сервер просто добавит отправляемые данные в этот файл
		key: 0,
		
		// timestamp начала загрузки
		timeStart: 0,
		
		totalTime: 0,
		error: false,
	}),
	methods: {
		//Resets all the upload specific data before a new upload
		resetKey(){
			this.progressPercent = 0;
			this.responseText = '';
			this.timeRemaining = '';
			this.btnStartText = 'Старт загрузки';
			this.uploadStarted = false;
			this.file = false;
			this.numberOfChunks = 0;
			this.aborted = false;
			this.paused = false;
			this.pauseChunk = 0;
			this.key = 0;
			this.timeStart = 0;
			this.totalTime = 0;
			this.error = false;
		},

		//Inital method called
		//Determines whether to begin/pause/resume an upload based on whether or not one is already in progress
		fire(){
			if(this.uploadStarted === true && this.paused === false){
				this.pauseUpload();
			} else if(this.uploadStarted === true && this.paused === true){
				this.resumeUpload();
			} else {
				this.processFiles();
			}
		},
		
		//Initial upload method
		//Pulls the size of the file being uploaded and calculated the number of chunks, then calls the recursive upload method
		processFiles(){
			//Reset the upload-specific variables
			this.resetKey();
			this.uploadStarted = true;
			
			//Some HTML tidying
			//Reset the background color of the progress bar in case it was changed by any earlier errors
			//Change the Upload button to a Pause button
			this.printResponse('Загрузка...', false);
			this.btnStartText = 'Пауза';
			
			//Alias the file input object
			this.file = this.$refs.file.files[0];
			if(!this.file){
				this.resetKey();
				this.printResponse('Файл для загрузки не выбран', true);
				return;
			}
			
			//Check the filesize. Obviously this is not very secure, so it has another check in inc/bigUpload.php
			//But this should be good enough to catch any immediate errors
			let fileSize = this.file.size;
			if(fileSize > this.maxFileSize){
				this.resetKey();
				this.printResponse('Файл, который вы выбрали, слишком велик', true);
				return;
			}
			
			//Calculate the total number of file chunks
			this.numberOfChunks = Math.ceil(fileSize / this.chunkSize);
			
			//Start the upload
			this.onStart();
			this.sendFile(0);
		},

		//Main upload method
		sendFile(chunk){
			//Set the time for the beginning of the upload, used for calculating time remaining
			this.timeStart = new Date().getTime();
			
			//Check if the upload has been cancelled by the user
			if(this.aborted === true) return;
			
			//Check if the upload has been paused by the user
			if(this.paused === true){
				this.pauseChunk = chunk;
				this.printResponse('Загрузка приостановлена', false);
				this.onPause();
				return;
			}
			
			//Set the byte to start uploading from and the byte to end uploading at
			let start = chunk * this.chunkSize;
			let stop = start + this.chunkSize;
			
			//Initialize a new FileReader object
			let reader = new FileReader();
			reader.onloadend = async (evt) => {
				//Build the AJAX request
				//
				//this.key is the temporary filename
				//If the server sees it as 0 it will generate a new filename and pass it back in the JSON object
				//this.key is then populated with the filename to use for subsequent requests
				//When this method sends a valid filename (i.e. key != 0), the server will just append the data being sent to that file.
				let xhr = new XMLHttpRequest();
				xhr.open('POST', this.scriptPath + '?' + new URLSearchParams({
					action: 'upload',
					key: this.key,
				}) + (this.scriptPathParams ? ('&' + this.scriptPathParams) : ''), true);
				xhr.setRequestHeader('Authorization', 'Bearer '+this.authModel.token);
				xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
				xhr.onreadystatechange = () => {
					if(xhr.readyState == 4){
						let response = JSON.parse(xhr.response);
						
						//If there's an error, call the error method and break the loop
						if(response.errorStatus !== 0 || xhr.status != 200){
							this.resetKey();
							this.printResponse(response.errorText, true);
							this.onFail(response);
							return;
						}
						
						//If it's the first chunk, set this.key to the server response (see above)
						if(chunk === 0 || this.key === 0){
							this.key = response.key;
						}
						
						//If the file isn't done uploading, update the progress bar and run this.sendFile again for the next chunk
						if(chunk < this.numberOfChunks){
							this.progressUpdate(chunk + 1);
							this.sendFile(chunk + 1);
						}
						//If the file is complete uploaded, instantiate the finalizing method
						else {
							this.sendFileData();
						}
					}
				};
				
				//Send the file chunk
				xhr.send(blob);
			};
			
			//checks if browser is Internet Explorer (IE FileReader doesn't have the readAsBinaryString method) 
			let isIE = ((navigator.userAgent.indexOf('MSIE') != -1) || (navigator.userAgent.indexOf('Trident') != -1));
			
			//Slice the file into the desired chunk
			//This is the core of the script. Everything else is just fluff.
			let blob = this.file.slice(start, stop);
			if(isIE){
				reader.readAsArrayBuffer(blob);
			} else {
				reader.readAsBinaryString(blob);
			}
		},

		//This method is for whatever housekeeping work needs to be completed after the file is finished uploading.
		//As it's setup now, it passes along the original filename to the server and the server renames the file and removes it form the temp directory.
		//This function could also pass things like this.file.type for the mime-type (although it would be more accurate to use php for that)
		//Or it could pass along user information or something like that, depending on the context of the application.
		sendFileData(){
			let xhr = new XMLHttpRequest();
			xhr.open('POST', this.scriptPath + '?' + new URLSearchParams({
				action: 'finish',
			}) + (this.scriptPathParams ? ('&' + this.scriptPathParams) : ''), true);
			xhr.setRequestHeader('Authorization', 'Bearer '+this.authModel.token);
			xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
			xhr.onreadystatechange = () => {
				if(xhr.readyState == 4){
					let response = JSON.parse(xhr.response);
					
					//If there's an error, call the error method
					if(response.errorStatus !== 0 || xhr.status != 200){
						this.resetKey();
						this.printResponse(response.errorText, true);
						this.onFail(response);
						return;
					}
					
					//Reset the upload-specific data so we can process another upload
					this.resetKey();
					
					//Change the submit button text so it's ready for another upload and spit out a sucess message
					this.printResponse('Файл успешно загружен', false);
					this.onSuccess(response);
					
					this.$refs.file.value = null;
				}
			};
			
			//Send the reques
			let data = new URLSearchParams({
				key: this.key,
				name: this.file.name,
			});
			xhr.send(data);
		},
		
		//This method cancels the upload of a file.
		//It sets this.aborted to true, which stops the recursive upload script.
		//The server then removes the incomplete file from the temp directory, and the html displays an error message.
		abortFileUpload(){
			this.aborted = true;
			let xhr = new XMLHttpRequest();
			xhr.open('POST', this.scriptPath + '?' + new URLSearchParams({
				action: 'abort',
			}) + (this.scriptPathParams ? ('&' + this.scriptPathParams) : ''), true);
			xhr.setRequestHeader('Authorization', 'Bearer '+this.authModel.token);
			xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
			xhr.onreadystatechange = () => {
				if(xhr.readyState == 4){
					let response = JSON.parse(xhr.response);
					
					//If there's an error, call the error method.
					if(response.errorStatus !== 0 || xhr.status != 200){
						this.resetKey();
						this.printResponse(response.errorText, true);
						this.onFail(response);
						this.onCancel();
						return;
					}
					
					this.resetKey();
					this.printResponse('Загрузка файла была отменена', true);
					this.onCancel();
				}
			};
			
			//Send the request
			let data = new URLSearchParams({
				key: this.key,
			});
			xhr.send(data);
		},
		
		//Pause the upload
		//Sets this.paused to true, which breaks the upload loop.
		//The current chunk is still stored in this.pauseChunk, so the upload can later be resumed.
		//In a production environment, you might want to have a cron job to clean up files that have been paused and never resumed,
		//because this method won't delete the file from the temp directory if the user pauses and then leaves the page.
		pauseUpload(){
			this.paused = true;
			this.printResponse('Загрузка на паузе', false);
			this.btnStartText = 'Продолжить';
		},
		
		//Resume the upload
		//Undoes the doings of this.pauseUpload and then re-enters the loop at the last chunk uploaded
		resumeUpload(){
			this.paused = false;
			this.printResponse('Загрузка...', false);
			this.btnStartText = 'Пауза';
			this.onResume();
			this.sendFile(this.pauseChunk);
		},
		
		//This method updates a simple progress bar by calculating the percentage of chunks uploaded.
		//Also includes a method to calculate the time remaining by taking the average time to upload individual chunks
		//and multiplying it by the number of chunks remaining.
		progressUpdate(progress){
			let percent = Math.ceil((progress / this.numberOfChunks) * 100);
			this.progressPercent = percent;
			
			//Calculate the estimated time remaining
			//Only run this every five chunks, otherwise the time remaining jumps all over the place (see: http://xkcd.com/612/)
			//if(progress % 5 === 0){
				//Calculate the total time for all of the chunks uploaded so far
				this.totalTime += (new Date().getTime() - this.timeStart);
				//console.log(this.totalTime);
				
				//Estimate the time remaining by finding the average time per chunk upload and
				//multiplying it by the number of chunks remaining, then convert into seconds
				let timeLeft = Math.ceil((this.totalTime / progress) * (this.numberOfChunks - progress) / 100);
				//console.log(Math.ceil(((this.totalTime / progress) * this.chunkSize) / 1024) + 'kb/s');
				
				this.timeRemaining = timeLeft + ' ' + lib.decl1(timeLeft, ['секунда', 'секунды', 'секунд']) + ' осталось';
			//}
		},
		
		//Simple response/error handler
		printResponse(responseText, error){
			this.responseText = responseText;
			this.timeRemaining = '';
			this.error = error;
		},
	},
	mounted(){},
}
</script>